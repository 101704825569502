import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Button } from "primereact/button";
import FormInput from "../../components/Form/FormInput";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { getDateOrUndefined } from "../../utils/dateHelper";
import Tag from "../../components/Tag";

function ManageProject({
  isOpen,
  handleClose,
  handleCreation,
  handleUpdate,
  loading,
  project,
}) {
  // Mandatory
  const [title, setTitle] = useState("");
  const [reference, setReference] = useState("");
  const [state, setState] = useState(CONSTANTS.PROJECT_STATES[0]);
  const [totalAmount, setTotalAmount] = useState(0);

  // Optionnal
  const [type, setType] = useState("");
  const [collectedFunds, setCollectedFunds] = useState(undefined);
  const [publicDescription, setPublicDescription] = useState("");
  const [privateDescription, setPrivateDescription] = useState("");
  const [netSellerPrice, setNetSellerPrice] = useState(undefined);
  const [acquiredSurface, setAcquiredSurface] = useState(undefined);
  const [regenerationContractSurface, setRegenerationContractSurface] =
    useState(undefined);
  const [resoldSurface, setResoldSurface] = useState(undefined);
  const [sieSurface, setSieSurface] = useState(undefined);
  const [cdiFees, setCdiFees] = useState(undefined);
  const [crescendoImvestFees, setCrescendoImvestFees] = useState(undefined);
  const [notaryFees, setNotaryFees] = useState(undefined);
  const [miscFees, setMiscFees] = useState(undefined);
  const [renting, setRenting] = useState(undefined);
  const [propertyTaxRate, setPropertyTaxRate] = useState(undefined);
  const [committeeAdvice, setCommitteeAdvice] = useState("");
  const [committeePositiveDecision, setCommitteePositiveDecision] =
    useState(undefined);
  const [latestCertificationDate, setLatestCertificationDate] =
    useState(undefined);
  const [notarySignatureDate, setNotarySignatureDate] = useState(undefined);

  useEffect(() => {
    if (project) {
      setTitle(project.title);
      setReference(project.reference);
      setState(project.state);
      setTotalAmount(project.total_amount);
      setType(project.type);
      setCollectedFunds(project.collected_funds);
      setPublicDescription(project.public_description);
      setPrivateDescription(project.private_description);
      setNetSellerPrice(project.net_seller_price);
      setAcquiredSurface(project.acquired_surface);
      setResoldSurface(project.resold_surface);
      setRegenerationContractSurface(project.regeneration_contract_surface);
      setSieSurface(project.sie_surface);
      setCdiFees(project.cdi_fees);
      setCrescendoImvestFees(project.crescendo_imvest_fees);
      setNotaryFees(project.notary_fees);
      setMiscFees(project.misc_fees);
      setRenting(project.renting);
      setPropertyTaxRate(project.property_tax_rate);
      setCommitteeAdvice(project.committee_advice);
      setCommitteePositiveDecision(project.committee_positive_decision);
      setLatestCertificationDate(
        getDateOrUndefined(project.latest_certification_dt)
      );
      setNotarySignatureDate(getDateOrUndefined(project.notary_signature_dt));
    } else {
      setTitle("");
      setReference("");
      setState(CONSTANTS.PROJECT_STATES[0]);
      setTotalAmount(0);
      setType(undefined);
      setCollectedFunds(undefined);
      setPublicDescription("");
      setPrivateDescription("");
      setNetSellerPrice(undefined);
      setAcquiredSurface(undefined);
      setResoldSurface(undefined);
      setRegenerationContractSurface(undefined);
      setSieSurface(undefined);
      setCdiFees(undefined);
      setCrescendoImvestFees(undefined);
      setNotaryFees(undefined);
      setMiscFees(undefined);
      setRenting(undefined);
      setPropertyTaxRate(undefined);
      setCommitteeAdvice("");
      setCommitteePositiveDecision(undefined);
      setLatestCertificationDate(undefined);
      setNotarySignatureDate(undefined);
    }
  }, [project]);

  const onValidate = () => {
    const projectForm = {
      title,
      reference,
      state: state,
      total_amount: totalAmount,
      type,
      collected_funds: collectedFunds,
      public_description: publicDescription,
      private_description: privateDescription,
      net_seller_price: netSellerPrice,
      acquired_surface: acquiredSurface,
      regeneration_contract_surface: regenerationContractSurface,
      resold_surface: resoldSurface,
      sie_surface: sieSurface,
      cdi_fees: cdiFees,
      crescendo_imvest_fees: crescendoImvestFees,
      notary_fees: notaryFees,
      misc_fees: miscFees,
      renting,
      property_tax_rate: propertyTaxRate,
      committee_advice: committeeAdvice,
      committee_positive_decision: committeePositiveDecision,
      latest_certification_dt: moment(latestCertificationDate).format(
        "YYYY-MM-DD"
      ),
      notary_signature_dt: moment(notarySignatureDate).format("YYYY-MM-DD"),
    };
    project ? handleUpdate(projectForm) : handleCreation(projectForm);
  };

  const stateTemplate = (option, props) => {
    if (!option) return props.placeholder;
    return (
      <Tag
        value={option.label}
        bgColor={
          CONSTANTS.PROJECT_STATES.find((ps) => ps.label === option.label)
            ?.color
        }
        rounded
      />
    );
  };

  const formIsValid =
    title.length > 2 &&
    reference.length > 2 &&
    state &&
    totalAmount !== undefined;

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={"validate"}
        label={`Valider ${project ? "la modification" : "l'ajout"}`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );
  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage text={`${project ? `${reference}` : "Ajouter un projet"}`} />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Titre public"} required isValid={title.length > 2}>
            <InputText
              value={title}
              placeholder="Veuillez saisir le titre du projet"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>
          <FormInput
            label={"Référence"}
            required
            isValid={reference.length > 2}
          >
            <InputText
              value={reference}
              placeholder="Veuillez saisir la référence du projet"
              onChange={(e) => setReference(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Etat"} required isValid={state}>
            <Dropdown
              value={state}
              optionValue="label"
              optionLabel="label"
              onChange={(e) => setState(e.value)}
              options={CONSTANTS.PROJECT_STATES}
              itemTemplate={stateTemplate}
              valueTemplate={stateTemplate}
              placeholder="Veuillez sélectionner un état"
            />
          </FormInput>

          <FormInput label={"Montant total en €"}>
            <InputNumber
              value={totalAmount}
              placeholder="Veuillez saisir le montant total"
              onChange={(e) => setTotalAmount(e.value)}
              min={0}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Type"}>
            <Dropdown
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANTS.PROJECT_TYPES}
              placeholder="Veuillez sélectionner un type de projet"
            />
          </FormInput>

          <FormInput label={"Fonds collectés en €"}>
            <InputNumber
              value={collectedFunds}
              placeholder="Veuillez saisir le montant collecté"
              onChange={(e) => setCollectedFunds(e.value)}
              min={0}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Prix net vendeur en €"}>
            <InputNumber
              value={netSellerPrice}
              placeholder="Veuillez saisir le montant"
              onChange={(e) => setNetSellerPrice(e.value)}
              min={0}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Fermage"}>
            <InputNumber
              value={renting}
              placeholder="Veuillez saisir le montant"
              onChange={(e) => setRenting(e.value)}
              min={0}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Taux taxe foncière"}>
            <InputNumber
              value={propertyTaxRate}
              placeholder="Veuillez saisir le taux"
              onChange={(e) => setPropertyTaxRate(e.value)}
              min={0}
              suffix="%"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Surface acquise en m²"}>
            <InputNumber
              value={acquiredSurface}
              placeholder="Veuillez saisir la surface acquise"
              onChange={(e) => setAcquiredSurface(e.value)}
              min={0}
              suffix=" m²"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Surface revendue en m²"}>
            <InputNumber
              value={resoldSurface}
              placeholder="Veuillez saisir la surface revendue"
              onChange={(e) => setResoldSurface(e.value)}
              min={0}
              suffix=" m²"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Surface sous contrat Régénération en m²"}>
            <InputNumber
              value={regenerationContractSurface}
              placeholder="Veuillez saisir la surface sous contrat Régénération"
              onChange={(e) => setRegenerationContractSurface(e.value)}
              min={0}
              suffix=" m²"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Surface d'Interet Ecologique en m²"}>
            <InputNumber
              value={sieSurface}
              placeholder="Veuillez saisir la surface"
              onChange={(e) => setSieSurface(e.value)}
              min={0}
              suffix=" m²"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Frais CDI en €"}>
            <InputNumber
              value={cdiFees}
              placeholder="Veuillez saisir le montant des frais"
              onChange={(e) => setCdiFees(e.value)}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Frais notaire en €"}>
            <InputNumber
              value={notaryFees}
              placeholder="Veuillez saisir le montant des frais"
              onChange={(e) => setNotaryFees(e.value)}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Frais Crescendo Imvest en €"}>
            <InputNumber
              value={crescendoImvestFees}
              placeholder="Veuillez saisir le montant des frais"
              onChange={(e) => setCrescendoImvestFees(e.value)}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Frais autres (géomètre, ...) en €"}>
            <InputNumber
              value={miscFees}
              placeholder="Veuillez saisir le montant des frais"
              onChange={(e) => setMiscFees(e.value)}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Description publique"}>
            <InputTextarea
              rows={3}
              autoResize
              value={publicDescription}
              onChange={(e) => setPublicDescription(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Description privée"}>
            <InputTextarea
              rows={3}
              autoResize
              value={privateDescription}
              onChange={(e) => setPrivateDescription(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Date de dernière certification"}>
            <Calendar
              id="certification-date"
              onChange={(e) => setLatestCertificationDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              maxDate={new Date()}
              value={latestCertificationDate}
              locale="fr"
            />
          </FormInput>

          <FormInput label={"Date de signature notaire"}>
            <Calendar
              id="notary-date"
              onChange={(e) => setNotarySignatureDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              maxDate={new Date()}
              value={notarySignatureDate}
              locale="fr"
            />
          </FormInput>

          <FormInput label={"Décision du comité"}>
            <div className="flex gap-x-5">
              {CONSTANTS.COMMITEE_DECISIONS.map((cd, i) => (
                <div key={i} className="flex gap-x-2">
                  <RadioButton
                    inputId={cd.label}
                    name={cd.label}
                    value={cd.value}
                    onChange={(e) =>
                      setCommitteePositiveDecision(e.target.value)
                    }
                    checked={committeePositiveDecision === cd.value}
                  />
                  <label htmlFor={cd.label}>{cd.label}</label>
                </div>
              ))}
            </div>
          </FormInput>

          <FormInput label={"Commentaire du comité"}>
            <InputTextarea
              rows={3}
              autoResize
              value={committeeAdvice}
              onChange={(e) => setCommitteeAdvice(e.target.value)}
            />
          </FormInput>
        </div>

        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageProject;
