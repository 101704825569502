import React, { useEffect, useState, useRef } from "react";
import { COLORS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { privateFetch } from "../../../utils/apiHelper";
import TitlePage from "../../../components/TitlePage";
import Button from "../../../components/Button";
import { useAccountContext } from "../../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import "../../Pages.scss";
import { formatDate } from "../../../utils/dateHelper";
import { TransactionDatatable } from "../../../modules/transaction/TransactionDatatable";
import moment from "moment";
import { TransactionDetail } from "../../../modules/transaction/TransactionDetail";
import SubAppSelector from "../../../components/SubAppSelectorREFACTO";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { useArchiveContext } from "../../../contexts/ArchiveContext";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import { useDebouncedCallback } from "use-debounce";
import slugify from "react-slugify";

function ArchivePage({ hidden }) {
  const exportRef = useRef(null);
  const { archiveContext, handleSelectedTransaction } = useArchiveContext();
  const { navigationContext, handleArchiveView } = useNavigationContext();
  const { accountContext } = useAccountContext();

  const [transactionList, setTransactionList] = useState([]);
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    refreshTransactionList();
  }, [archiveContext]);

  useEffect(() => {
    archiveContext?.selectedTransactionId && fetchTransactionDetail();
  }, [archiveContext?.selectedTransactionId]);

  const refreshTransactionList = useDebouncedCallback(() => {
    fetchTransactionList();
  }, 500);

  const fetchTransactionList = async () => {
    await privateFetch("GET", `/ia/${accountContext.id}/transaction/`).then(
      (res) => {
        if (res) setTransactionList(res);
      }
    );
  };

  const fetchTransactionDetail = async () => {
    await privateFetch(
      "GET",
      `/ia/${accountContext.id}/transaction/${archiveContext.selectedTransactionId}/`
    ).then((res) => {
      if (res) {
        setTransaction(res);
      } else {
        setTransaction(undefined);
      }
    });
  };

  const handleSelectTransaction = (targetTransaction) => {
    handleSelectedTransaction(targetTransaction);
    handleArchiveView("TRANSACTION");
  };

  const breadcrumbItems = () => {
    const items = [
      {
        label: "Historique des transactions",
        onClick: () => handleArchiveView("DATATABLE"),
        isActive: navigationContext?.archiveView === "DATATABLE",
      },
    ];
    // Transaction view
    transaction &&
      items.push({
        label: `${transaction.investor.name} ${transaction.investor.first_name} - ${transaction.transaction_type.ref} - ${formatDate(transaction.execution_date)}`,
        onClick: () => handleArchiveView("TRANSACTION"),
        isActive: navigationContext?.archiveView === "TRANSACTION",
      });
    return items;
  };

  const mainContent = () => {
    switch (navigationContext?.archiveView) {
      case "DATATABLE":
        return (
          <div className="p-4 w-full overflow-y-hidden">
            <div className="full-page-datatable">
              <TransactionDatatable
                data={transactionList}
                exportFileName={`export_transactions_${moment().format("DD/MM/YYYY")}`}
                selectTransaction={handleSelectTransaction}
                showInvestorName
              />
            </div>
          </div>
        );
      case "TRANSACTION":
        return (
          <div className="flex flex-row h-full w-full">
            <div className="h-auto overflow-y-scroll w-full">
              <TransactionDetail
                transaction={transaction}
                handleUpdateDocument={handleDocumentDownload}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleDocumentDownload = (document) => {
    if (document) window.open(document.file, "_blank");
  };

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Header */}
      <BreadCrumb items={breadcrumbItems()} />

      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper">
        {mainContent()}
      </div>
    </div>
  );
}

export default ArchivePage;
