import React from "react";
import ImageItem from "./Image/ImageItem";

function ImageList({ images = [], handleImageUpdate }) {
  if (!images?.length)
    return (
      <span className="empty-section-message">Aucune image à afficher...</span>
    );
  return (
    <div className="flex flex-row flex-wrap gap-5">
      {images?.map((i) => (
        <ImageItem key={i.id} image={i} onClick={() => handleImageUpdate(i)} />
      ))}
    </div>
  );
}

export default ImageList;
