import { Button } from "primereact/button";
import React from "react";

function Section({
  label,
  actions,
  children,
  containerClassName = "",
  labelClassName = "",
}) {
  return (
    <div className={`flex flex-col gap-y-3 ${containerClassName}`}>
      <div className="flex flex-row w-full justify-between items-center h-10">
        <h3 className={`${labelClassName}`}>{label}</h3>
        <div className="flex flex-row gap-x-3">
          {actions &&
            actions.map((a, i) => (
              <Button
                key={i}
                size="small"
                text
                outlined
                rounded
                disabled={a.disabled}
                label={a.label}
                iconPos="left"
                icon={`pi ${a.icon}`}
                onClick={a.onClick}
                className={` ${a.disabled && "opacity-40"}`}
              />
            ))}
        </div>
      </div>
      <div className="flex flex-col gap-y-6">{children}</div>
    </div>
  );
}

export default Section;
