import React from "react";
import AppRouter from "./Router";
import { PrimeReactProvider, addLocale } from "primereact/api";
import { HashRouter } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";

import AppContexts from "./contexts/Contexts";
import { locale } from "primereact/api";

import "./App.scss";
import "./assets/css/index.css";
import "./assets/css/dashboard.css";
import { localeJson } from "./assets/theme/fr";

import "./assets/theme/custom-theme/theme.scss";

import "primeicons/primeicons.css";

addLocale("fr", localeJson.fr);
locale("fr");

function App() {
  return (
    <React.Fragment>
      <PrimeReactProvider>
        <AppContexts>
          <ConfirmDialog />
          <HashRouter>
            <AppRouter />
          </HashRouter>
        </AppContexts>
      </PrimeReactProvider>
    </React.Fragment>
  );
}

export default App;
