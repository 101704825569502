import React, { useState } from "react";
import { MEDIAS } from "../ressources/MEDIAS";
import "./MenuTitleREFACTO.scss";

export default function MenuTitle(props) {
  return (
    <div className={`app-name`}>
      <img className="logo" src={props.img} />
      {props.showLabel && <div className="name">{props.label}</div>}
    </div>
  );
}
