import React from "react";
import { MEDIAS } from "../ressources/MEDIAS";

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function KeyIndicatorButton({ keyIndicator, isSelected, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`h-44 w-60 p-5 flex flex-col justify-between rounded-lg bg-white shadow-md cursor-pointer transition-all hover:shadow-lg ${isSelected ? "outline-2 outline outline-custom-blue" : ""}`}
    >
      <img src={MEDIAS[keyIndicator.icon]} className="h-10 w-10" />
      <span className="text-sm font-medium">{keyIndicator.label}</span>
      <span className="text-lg font-bold">
        {numberWithSpaces(Math.round(keyIndicator.value)) + " €"}
      </span>
    </div>
  );
}

export default KeyIndicatorButton;
