import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { Button } from "primereact/button";
import FormInput from "../../components/Form/FormInput";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { privateFetch } from "../../utils/apiHelper";
import { useAccountContext } from "../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import Tag from "../../components/Tag";
import { SelectButton } from "primereact/selectbutton";

function ManageIntermediate({
  isOpen,
  handleClose,
  handleCreation,
  handleUpdate,
  handleDelete,
  loading,
  intermediate,
}) {
  const {
    getActiveScope,
    getAuthorizedScopeList,
    getScopesListFromIds,
    getScopeFromId,
  } = useAuthorizationsContext();
  const { accountContext } = useAccountContext();

  const autocompleteRef = useRef(null);

  const [filteredIntermediates, setFilteredIntermediates] = useState([]);
  const [query, setQuery] = useState("");
  const [existingIntermediate, setExistingIntermediate] = useState(undefined);
  const [newIntermediate, setNewIntermediate] = useState(false);

  // Mandatory
  const [name, setName] = useState("");
  const [maxFeeRate, setMaxFeeRate] = useState(3);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [selectedScopeList, setSelectedScopeList] = useState([]);

  useEffect(() => {
    if (!intermediate) {
      fetchIntermediateList();
    }
  }, [isOpen]);

  useEffect(() => {
    if (intermediate) {
      setName(intermediate.name);
      setMaxFeeRate(intermediate.max_fee_rate * 100);
      setEmail(intermediate.email);
      setPhone(intermediate.phone);
      setAddress(intermediate.address);
      setSelectedScopeList(getScopesListFromIds(intermediate.linked_scopes));
    } else {
      // Form
      setName("");
      setMaxFeeRate(3);
      setEmail("");
      setPhone("");
      setAddress("");
      setSelectedScopeList([]);
      // Autocomplete
      setExistingIntermediate(undefined);
      setQuery("");
      setNewIntermediate(false);
    }
  }, [intermediate, isOpen]);

  const fetchIntermediateList = async (q = "") => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/autocomplete/?query=${q}`
    ).then((res) => {
      setFilteredIntermediates(res);
    });
  };

  const onValidate = () => {
    const intermediateForm = {
      name,
      max_fee_rate: maxFeeRate / 100,
      email,
      phone,
      address,
      linked_scopes: selectedScopeList.map((ss) => ss.id),
    };
    intermediate
      ? handleUpdate(intermediateForm)
      : handleCreation(intermediateForm);
  };

  const handleIntermediateSelection = (targetIntermediate) => {
    if (!targetIntermediate.id) {
      // Create new intermediate
      setNewIntermediate(true);
      setName(query);
    } else {
      // Existing intermediate
      setExistingIntermediate(targetIntermediate);
    }
  };

  const formIsValid = name.length > 2;

  const footerButtons = (
    <div className="manage-footer">
      {intermediate && (
        <Button
          key={"delete"}
          label={`Supprimer l'intermédiaire`}
          onClick={handleDelete}
          severity="danger"
          outlined
        />
      )}
      <Button
        key={"validate"}
        label={`Valider ${intermediate ? "la modification" : "l'ajout"}`}
        loading={loading}
        // TODO REWORK
        // disabled={
        //   existingIntermediate ? intermediateExistInScope() : !formIsValid
        // }
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const intermediateTemplate = (item) => {
    if (!item.id) {
      return (
        <div className="row-gap">
          <i className="pi pi-user-plus"></i>
          <span className="autocomplete-new-item">
            Ajouter un nouvel intermédiaire
          </span>
        </div>
      );
    } else {
      return (
        <div className="autocomplete-item-container">
          <span>{`${item.name}`}</span>
          <div className="flex gap-x-1">
            {item?.linked_scopes?.map((lsId) => (
              <Tag key={lsId} value={getScopeFromId(lsId).label} />
            ))}
          </div>
        </div>
      );
    }
  };

  const intermediateFullName = (value) => {
    return `${value.name}`;
  };

  // TODO REWORK
  function intermediateExistInScope() {
    return existingIntermediate.linked_scopes.some(
      (lsId) => lsId === getActiveScope().id
    );
  }

  const intermediateAutocompletion = () => {
    return (
      <div className="form-wrapper">
        <FormInput label={"Nom de la société"}>
          <AutoComplete
            ref={autocompleteRef}
            field={intermediateFullName}
            inputClassName="autocomplete-input"
            value={query}
            suggestions={[...filteredIntermediates, { id: 0 }]}
            completeMethod={(e) => fetchIntermediateList(e.query)}
            onChange={(e) => {
              setQuery(e.value);
              setExistingIntermediate(undefined);
            }}
            onSelect={(e) => handleIntermediateSelection(e.value)}
            itemTemplate={intermediateTemplate}
            dropdown
            placeholder="Veuillez saisir le nom de la société"
          />
        </FormInput>

        {/* TODO REWORK*/}
        {/* {existingIntermediate && intermediateExistInScope() && (
          <Message
            severity="warn"
            text={`Cet intermédiaire existe déjà dans ${getActiveScope().label
              }`}
          />
        )}
        {existingIntermediate && !intermediateExistInScope() && (
          <Message
            severity="success"
            text={`Cet intermédiaire sera ajouté à ${getActiveScope().label}`}
          />
        )} */}
      </div>
    );
  };

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${
            intermediate ? intermediate.name : "Ajouter un intermédiaire"
          }`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        {newIntermediate || intermediate ? (
          <div className="form-wrapper">
            <FormInput label={"Nom"} required isValid={name.length > 2}>
              <InputText
                id="name"
                value={name}
                placeholder="Veuillez saisir le nom de la société"
                onChange={(e) => setName(e.target.value)}
              />
            </FormInput>
            <FormInput label={"Périmètre"}>
              <SelectButton
                multiple
                value={selectedScopeList}
                onChange={(e) => setSelectedScopeList(e.value)}
                options={getAuthorizedScopeList()}
                optionLabel="label"
              />
            </FormInput>
            <FormInput label={"Taux maximum"} required isValid={maxFeeRate}>
              <InputNumber
                value={maxFeeRate}
                placeholder="Veuillez saisir le taux maximum"
                onChange={(e) => setMaxFeeRate(e.value)}
                min={0}
                suffix="%"
                locale="fr-FR"
              />
            </FormInput>

            <FormInput label={"Email"}>
              <InputText
                id="email"
                value={email}
                placeholder="Veuillez saisir l'email de la société"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormInput>

            <FormInput label={"Téléphone"}>
              <InputText
                id="phone"
                value={phone}
                placeholder="Veuillez saisir le téléphone de la société"
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormInput>

            <FormInput label={"Adresse"}>
              <InputText
                id="address"
                value={address}
                placeholder="Veuillez saisir l'adresse de la société"
                onChange={(e) => setAddress(e.target.value)}
              />
            </FormInput>
          </div>
        ) : (
          intermediateAutocompletion()
        )}
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageIntermediate;
