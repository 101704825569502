import React from "react";
import { AccountContextProvider } from "./AccountContext";
import { NavigationContextProvider } from "./NavigationContext";
import { AuthorizationsContextProvider } from "./AuthorizationsContext";
import { ToastContextProvider } from "./ToastContext";
import { InvestorContextProvider } from "./InvestorContext";
import { IntermediateContextProvider } from "./IntermediateContext";
import { ResidenceContextProvider } from "./ResidenceContext";
import { UserContextProvider } from "./UserContext";
import { BeneficiaryContextProvider } from "./BeneficiaryContext";
import { ArchiveContextProvider } from "./ArchiveContext";

const AppContexts = ({ children }) => (
  <AccountContextProvider>
    <AuthorizationsContextProvider>
      <ToastContextProvider>
        <InvestorContextProvider>
          <ArchiveContextProvider>
            <IntermediateContextProvider>
              <ResidenceContextProvider>
                <BeneficiaryContextProvider>
                  <UserContextProvider>
                    <NavigationContextProvider>
                      {children}
                    </NavigationContextProvider>
                  </UserContextProvider>
                </BeneficiaryContextProvider>
              </ResidenceContextProvider>
            </IntermediateContextProvider>
          </ArchiveContextProvider>
        </InvestorContextProvider>
      </ToastContextProvider>
    </AuthorizationsContextProvider>
  </AccountContextProvider>
);

export default AppContexts;
