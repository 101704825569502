import React from "react";
import "./Data.scss";
import Tag from "../Tag";

/*
Tag : {
  value, 
  bgColor, 
  onClick, 
  large, 
  icon
}
*/

function DataMultipleTags({ label, large, tags }) {
  const emptyValue = (
    <p className="text-slate-400 italic font-extralight">{" - "}</p>
  );

  return (
    <div
      className={`flex flex-col gap-y-1 data-container ${large ? "w-[340px]" : "w-40"}`}
    >
      <span className="text-gray-500 text-sm">{label}</span>
      <div className="flex flex-row gap-x-2">
        {tags.length
          ? tags.map((t, i) => (
              <Tag
                key={i}
                value={t.value}
                bgColor={t.bgColor}
                onClick={t.onClick}
                icon={t.icon}
                rounded={t.rounded}
              />
            ))
          : emptyValue}
      </div>
    </div>
  );
}

export default DataMultipleTags;
