import React, { createContext, useContext, useState, useEffect } from "react";
import { getNavigation, saveNavigation } from "../utils/navigationHelper";
import { useAccountContext } from "./AccountContext";
import { getCurrentReleaseNote } from "../utils/releaseNoteHelper";
import BugReportDialog from "../components/BugReport/BugReportDialog";
import ReleaseNoteDialog from "../components/ReleaseNote/ReleaseNoteDialog";
import { useInvestorContext } from "./InvestorContext";
import { useIntermediateContext } from "./IntermediateContext";

const NavigationContext = createContext(null);

export const NavigationContextProvider = ({ children }) => {
  const { isLoggedIn } = useAccountContext();
  const { handleSelectedIntermediate } = useIntermediateContext();
  const { handleSelectedTransaction } = useInvestorContext();

  const [navigationContext, setNavigationContext] = useState(
    getNavigation() || {
      expandedMenu: true,
      showReleaseNote: true,
      currentSubapp: "investor",
      investorView: "DATATABLE",
      archiveView: "DATATABLE",
      intermediateView: "DATATABLE",
      residenceView: "DATATABLE",
      beneficiaryView: "DATATABLE",
    }
  );

  // Release Note Dialog
  const [releaseNoteContent, setReleaseNoteContent] = useState(undefined);
  // Bug Report Dialog
  const [bugReportIsVisible, setBugReportIsVisible] = useState(undefined);

  useEffect(() => {
    if (navigationContext) {
      saveNavigation(navigationContext);
    }
  }, [navigationContext]);

  useEffect(() => {
    if (navigationContext.showReleaseNote && isLoggedIn) {
      getCurrentReleaseNote() && setReleaseNoteContent(getCurrentReleaseNote());
    }
  }, [isLoggedIn, navigationContext.showReleaseNote]);

  const changeSubapp = (targetSubapp) => {
    setNavigationContext({ ...navigationContext, currentSubapp: targetSubapp });
  };

  const isPreproduction = window.location.href.includes("demo");
  const handleInvestorView = (targetView) => {
    setNavigationContext({
      ...navigationContext,
      currentSubapp: "investor",
      investorView: targetView,
    });
  };

  const handleArchiveView = (targetView) => {
    setNavigationContext({
      ...navigationContext,
      currentSubapp: "archive",
      archiveView: targetView,
    });
  };

  const handleIntermediateView = (targetView) => {
    setNavigationContext({
      ...navigationContext,
      currentSubapp: "intermediate",
      intermediateView: targetView,
    });
  };

  const handleResidenceView = (targetView) => {
    setNavigationContext({
      ...navigationContext,
      currentSubapp: "residence",
      residenceView: targetView,
    });
  };

  const handleBeneficiaryView = (targetView) => {
    setNavigationContext({
      ...navigationContext,
      currentSubapp: "beneficiary",
      beneficiaryView: targetView,
    });
  };

  const navigateToIntermediate = (intermediate) => {
    setNavigationContext({
      ...navigationContext,
      currentSubapp: "intermediate",
      intermediateView: "INTERMEDIATE",
    });
    handleSelectedIntermediate(intermediate);
  };

  const navigateToTransaction = (transaction) => {
    setNavigationContext({
      ...navigationContext,
      currentSubapp: "investor",
      investorView: "TRANSACTION",
    });
    handleSelectedTransaction(transaction);
  };

  const hideReleaseNote = () => {
    setReleaseNoteContent(undefined);
    setNavigationContext({
      ...navigationContext,
      showReleaseNote: false,
    });
  };

  const showReleaseNote = () => {
    setNavigationContext({
      ...navigationContext,
      showReleaseNote: true,
    });
  };

  const hideBugReport = () => {
    setBugReportIsVisible(false);
  };

  const showBugReport = () => {
    setBugReportIsVisible(true);
  };

  const setExpandedMenu = (expanded) => {
    setNavigationContext({ ...navigationContext, expandedMenu: expanded });
  };

  return (
    <NavigationContext.Provider
      value={{
        navigationContext,
        setNavigationContext,
        changeSubapp,
        isPreproduction,
        hideReleaseNote,
        showReleaseNote,
        showBugReport,
        handleInvestorView,
        handleArchiveView,
        handleIntermediateView,
        handleResidenceView,
        handleBeneficiaryView,
        setExpandedMenu,
        navigateToIntermediate,
        navigateToTransaction,
      }}
    >
      {children}
      <ReleaseNoteDialog
        content={releaseNoteContent}
        handleClose={hideReleaseNote}
      />
      <BugReportDialog
        visible={bugReportIsVisible}
        handleClose={hideBugReport}
      />
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  return useContext(NavigationContext);
};
