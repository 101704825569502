import React, { useState, useEffect, useMemo } from "react";
import { privateFetch } from "../../../utils/apiHelper";
import { ResidenceDatatable } from "../../../modules/residence/ResidenceDatatable";
import { ResidenceDetail } from "../../../modules/residence/ResidenceDetail";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import { confirmDialog } from "primereact/confirmdialog";
import ManageDocument from "../../../modules/managers/ManageDocument";
import ManageResidence from "../../../modules/managers/ManageResidence";
import ManageImage from "../../../modules/managers/ManageImage";
import "../../Pages.scss";
import { useResidenceContext } from "../../../contexts/ResidenceContext";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import slugify from "react-slugify";
import { useDebouncedCallback } from "use-debounce";

function ResidencePage({ hidden }) {
  const { residenceContext, handleSelectedResidence } = useResidenceContext();
  const { navigationContext, handleResidenceView } = useNavigationContext();
  const {
    authorizationsContext,
    getActiveScopesNames,
    getActiveScope,
    getSubappAuthorizationType,
  } = useAuthorizationsContext();

  const { accountContext } = useAccountContext();

  const [residenceList, setResidenceList] = useState([]);
  const [residence, setResidence] = useState(null);
  const [residenceModuleOpen, setResidenceModuleOpen] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [imageModuleOpen, setImageModuleOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchResidenceList();
  }, []);

  useEffect(() => {
    refreshResidenceList();
  }, [residenceContext]);

  useEffect(() => {
    residenceContext?.selectedResidenceId && fetchResidenceDetail();
  }, [residenceContext?.selectedResidenceId]);

  const refreshResidenceList = useDebouncedCallback(() => {
    fetchResidenceList();
  }, 500);

  const isReadWrite = useMemo(() => {
    return getSubappAuthorizationType("residens", "PROJECT") === "READ_WRITE";
  }, [authorizationsContext]);

  const handleResidenceSelection = async (targetResidence) => {
    handleSelectedResidence(targetResidence);
    handleResidenceView("RESIDENCE");
  };

  const fetchResidenceList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/residence/?query=${slugify(
        residenceContext.filters.query
      )}`
    ).then((res) => {
      if (res) setResidenceList(res);
    });
  };

  const fetchResidenceDetail = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/residence/${
        residenceContext?.selectedResidenceId
      }/?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) setResidence(res);
    });
  };

  const createResidence = async (residenceForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/residence/`,
      "Une erreur est survenue lors de la création de la residence",
      "La residence a bien été créé.",
      JSON.stringify(residenceForm)
    ).then((response) => {
      if (response) {
        setResidenceModuleOpen(false);
        setLoading(false);
        fetchResidenceList();
        handleSelectedResidence(response);
        handleResidenceView("RESIDENCE");
      }
    });
  };

  const updateResidence = async (residenceForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/residence/${residence.id}/`,
      "Une erreur est survenue lors de la modification de la residence",
      "La residence a bien été modifié.",
      JSON.stringify(residenceForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setResidenceModuleOpen(false);
        fetchResidenceList();
        fetchResidenceDetail();
      }
    });
  };

  const deleteResidence = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/residence/${residence.id}/`,
      "Une erreur est survenue lors de la suppression de la résidence.",
      "La résidence a bien été supprimée."
    ).then((response) => {
      if (response) {
        setLoading(false);
        handleResidenceView("DATATABLE");
        setResidence(undefined);
        handleSelectedResidence(undefined);
        fetchResidenceList();
      }
    });
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/residence/${residence.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const deleteDocument = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const confirmDocumentDeletion = () => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${document.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteDocument(),
    });
  };

  const uploadImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/residence/${residence.id}/image/`,
      "Une erreur est survenue lors de l'ajout d'image.",
      "L'image a bien été ajoutée.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/image/${image.id}/`,
      "Une erreur est survenue lors de la modification de l'image.",
      "L'image a bien été modifié.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const deleteImage = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/image/${image.id}/`,
      "Une erreur est survenue lors de la suppression de l'image.",
      "L'image a bien été supprimée."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImage(undefined);
        setImageModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const confirmResidenceDeletion = () => {
    setResidenceModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer la résidence ${residence.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteResidence(),
    });
  };

  const handleCreateResidence = () => {
    setResidence(undefined);
    setResidenceModuleOpen(true);
  };

  const handleUpdateResidence = () => {
    setResidenceModuleOpen(true);
  };

  const handleCreateResidenceImage = () => {
    setImage(undefined);
    setImageModuleOpen(true);
  };

  const handleUpdateResidenceImage = (image) => {
    setImage(image);
    setImageModuleOpen(true);
  };

  const handleCreateResidenceDocument = () => {
    setDocument(undefined);
    setDocumentModuleOpen(true);
  };

  const handleUpdateResidenceDocument = (document) => {
    setDocument(document);
    setDocumentModuleOpen(true);
  };

  const handleDownloadDocument = (document) => {
    if (document) window.open(document.file, "_blank");
  };

  const handleDownloadImage = (image) => {
    if (image) window.open(image.picture, "_blank");
  };

  const breadcrumbItems = () => {
    const items = [
      {
        label: "Résidences",
        onClick: () => handleResidenceView("DATATABLE"),
        isActive: navigationContext?.residenceView === "DATATABLE",
      },
    ];
    residence &&
      items.push({
        label: `${residence.name}`,
        onClick: () => handleResidenceView("RESIDENCE"),
        isActive: navigationContext?.residenceView === "RESIDENCE",
      });
    return items;
  };

  const mainContent = () => {
    switch (navigationContext?.residenceView) {
      case "DATATABLE":
        return (
          <div className="p-4 w-full overflow-y-hidden">
            <div className="full-page-datatable">
              <ResidenceDatatable
                loading={loading}
                data={residenceList}
                selectItem={handleResidenceSelection}
                handleCreateResidence={isReadWrite && handleCreateResidence}
              />
            </div>
          </div>
        );
      case "RESIDENCE":
        return (
          <div className="flex flex-row h-full">
            <ResidenceDetail
              residence={residence}
              handleUpdateDocument={
                isReadWrite
                  ? handleUpdateResidenceDocument
                  : handleDownloadDocument
              }
              handleUpdateImage={
                isReadWrite ? handleUpdateResidenceImage : handleDownloadImage
              }
              handleCreateImage={isReadWrite && handleCreateResidenceImage}
              handleCreateDocument={
                isReadWrite && handleCreateResidenceDocument
              }
              handleResidenceUpdate={isReadWrite && handleUpdateResidence}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Breadcrumb */}
      <BreadCrumb items={breadcrumbItems()} />

      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper">
        {mainContent()}
      </div>

      {/* Modules */}
      <ManageResidence
        loading={loading}
        residence={residence}
        isOpen={residenceModuleOpen}
        handleClose={() => setResidenceModuleOpen(false)}
        handleCreation={createResidence}
        handleUpdate={updateResidence}
        handleDelete={confirmResidenceDeletion}
      />

      <ManageDocument
        loading={loading}
        document={document}
        isOpen={documentModuleOpen}
        handleClose={() => setDocumentModuleOpen(false)}
        uploadDocument={uploadDocument}
        updateDocument={updateDocument}
        deleteDocument={confirmDocumentDeletion}
      />

      <ManageImage
        image={image}
        loading={loading}
        isOpen={imageModuleOpen}
        onClose={() => {
          setImage(null);
          setImageModuleOpen(false);
        }}
        onCreate={uploadImage}
        onUpdate={updateImage}
        onDelete={deleteImage}
      />
    </div>
  );
}

export default ResidencePage;
