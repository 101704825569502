import React, { useState, useEffect } from "react";
import { useAccountContext } from "../../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { privateFetch } from "../../../utils/apiHelper";
import ToolboxDocumentItem from "../../../components/ToolboxDocumentItem";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Section from "../../../components/Display/Section";
import { CONSTANTS } from "../../../ressources/CONSTANTS";

function ToolboxPage({ hidden }) {
  const { accountContext } = useAccountContext();
  const { getAuthorizedScopeList } = useAuthorizationsContext();
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    fetchToolboxDocumentList();
  }, []);

  const fetchToolboxDocumentList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/toolbox/?scopes=${getAuthorizedScopeList().map((scope) => scope.name)}`
    ).then((res) => {
      if (res) {
        setDocumentList(res);
      }
    });
  };

  const handleDocument = (document) => {
    window.open(document.file, "_blank");
  };

  const mainContent = (
    <div className="h-full overflow-y-scroll">
      <div className="main-content-container">
        {documentList.map((dl) => (
          <Section
            key={dl.key}
            label={`Documents ${dl.label}`}
            labelClassName={`!text-${dl.color}`}
          >
            {CONSTANTS.TOOLBOX_DOCUMENTS.map((TD) => (
              <div className="flex flex-col gap-y-3" key={TD.key}>
                <h4>{TD.label}</h4>
                {dl[TD.key].length ? (
                  <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
                    {dl[TD.key].map((d, i) => (
                      <ToolboxDocumentItem
                        key={i}
                        document={d}
                        onClick={handleDocument}
                      />
                    ))}
                  </div>
                ) : (
                  <span className="empty-section-message">
                    Aucun document à afficher...
                  </span>
                )}
              </div>
            ))}
          </Section>
        ))}
      </div>
    </div>
  );

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Header */}
      <BreadCrumb
        items={[
          {
            label: "Boîte à outils",
            isActive: true,
          },
        ]}
      />
      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper">{mainContent}</div>
    </div>
  );
}

export default ToolboxPage;
