import React from "react";
import "./SubAppSelectorREFACTO.scss";

export default function SubAppSelector({ authorizedScopeList, activeScopeList, onSelect, singleChoice = false }) {
  return (
    <div className={`subapp-selector-container`}>
      {!singleChoice && <div
        onClick={() => onSelect(authorizedScopeList)}
        className={activeScopeList.length > 1 ? `selector-label selected bg-custom-blue` : `selector-label`}>
        Tous
      </div>}
      {authorizedScopeList.map(scope => (
        <div
          onClick={() => onSelect([scope])}
          className={(activeScopeList.length == 1 && activeScopeList[0].name == scope.name) ? `selector-label selected bg-${scope.main_color}` : `selector-label`}>
          {scope.label}
        </div>
      ))}
    </div>
  );

}
