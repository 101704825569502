import React from 'react';
import { ReactComponent as Rocket } from '../assets/icon/rocket.svg';
import { ReactComponent as Bug } from '../assets/icon/bug.svg';

function CustomIcon(props) {
  switch (props.icon) {
    case 'rocket':
      return <Rocket {...props} />;
    case 'bug':
      return <Bug {...props} />;
    default:
      return null;
  }
}

export default CustomIcon;
