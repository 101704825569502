import React from "react";
import Data from "../../components/Display/Data";
import DataTag from "../../components/Display/DataTag";
import DocumentList from "../../components/DocumentList";
import Section from "../../components/Display/Section";
import ImageList from "../../components/ImageList";
import { CONSTANTS } from "../../ressources/CONSTANTS";

export const ProjectDetail = ({
  project,
  handleUpdateDocument,
  handleUpdateImage,
  handleCreateImage,
  handleCreateDocument,
  handleProjectUpdate,
}) => {
  const informationActions = handleProjectUpdate && [
    {
      onClick: handleProjectUpdate,
      label: "Modifier les informations",
      icon: "pi-pencil",
    },
  ];

  const documentActions = handleCreateDocument && [
    {
      onClick: handleCreateDocument,
      label: "Ajouter une document",
      icon: "pi-plus",
    },
  ];

  const imageActions = handleCreateImage && [
    {
      onClick: handleCreateImage,
      label: "Ajouter une image",
      icon: "pi-plus",
    },
  ];

  if (!project) return null;

  return (
    <div className="main-content-container h-auto overflow-y-scroll">
      <Section label="Informations" actions={informationActions}>
        <div className="data-section-row">
          <Data
            key="title"
            label={"Titre public"}
            value={project.title}
            large
          />
          <Data key="reference" label={"Référence"} value={project.reference} />
          <DataTag
            key="state"
            label={"Etat"}
            value={project.state}
            bgColor={
              CONSTANTS.PROJECT_STATES.find((ps) => ps.label === project.state)
                ?.color
            }
            rounded
          />
          <DataTag key="type" label={"Type"} value={project.type} rounded />
        </div>

        <div className="data-section-row">
          <DataTag
            key="decision"
            label={"Décision comité"}
            value={
              project.committee_positive_decision != undefined
                ? project.committee_positive_decision
                  ? "Favorable"
                  : "Défavorable"
                : undefined
            }
            bgColor={
              project.committee_positive_decision
                ? "bg-custom-green"
                : "bg-custom-red"
            }
            rounded
            large
          />
          <Data
            key="comment"
            label={"Commentaire comité"}
            value={project.committee_advice}
            multiline
            large
          />
        </div>

        <div className="data-section-row">
          <Data
            key="aquiredSurface"
            label={"Surface acquise"}
            value={project.acquired_surface}
            type="hectare"
          />
          <Data
            key="resoldSurface"
            label={"Surface revendue"}
            value={project.resold_surface}
            type="hectare"
          />
          <Data
            key="sieSurface"
            label={"Surface d'Interet Ecologique"}
            value={project.sie_surface}
            type="hectare"
            large
          />
          <Data
            key="regenerationSurface"
            label={"Surface sous contrat Régénération"}
            value={project.regeneration_contract_surface}
            type="hectare"
            large
          />
        </div>

        <div className="data-section-row">
          <Data
            key="totalAmount"
            label={"Montant total"}
            value={project.total_amount}
            type={"currency"}
          />
          <Data
            key="netSellerPrice"
            label={"Prix net vendeur"}
            value={project.net_seller_price}
            type={"currency"}
          />
          <Data
            key="renting"
            label={"Fermage"}
            value={project.renting}
            type={"currency"}
          />
          <Data
            key="propertyTaxRate"
            label={"Taux taxe foncière"}
            value={project.property_tax_rate}
          />

          <Data
            key="cdiFee"
            label={"Frais CDI"}
            value={project.cdi_fees}
            type={"currency"}
          />
          <Data
            key="notaryFee"
            label={"Frais notaire"}
            value={project.notary_fees}
            type={"currency"}
          />
          <Data
            key="ciFee"
            label={"Frais Crescendo Invest"}
            value={project.crescendo_imvest_fees}
            type={"currency"}
            large
          />
          <Data
            key="miscFee"
            label={"Frais autres (géomètre...)"}
            value={project.misc_fees}
            type={"currency"}
            large
          />
        </div>

        <div className="data-section-row">
          <Data
            key="certificationDt"
            label={"Dernière certification"}
            value={project.latest_certification_dt}
            type={"date"}
            large
          />
          <Data
            key="signatureDt"
            label={"Date signature notaire"}
            value={project.notary_signature_dt}
            type={"date"}
            large
          />
        </div>

        <div className="data-section-row">
          <Data
            key="publicDescription"
            label={"Description publique"}
            value={project.public_description}
            multiline
          />
        </div>
        <div className="data-section-row">
          <Data
            key="privateDescription"
            label={"Description privée"}
            value={project.private_description}
            multiline
          />
        </div>
      </Section>
      <Section label="Documents" actions={documentActions}>
        <DocumentList
          handleExistingDocument={handleUpdateDocument}
          requiredDocuments={[]}
          documents={project && project.documents}
        />
      </Section>
      <Section label="Cartographie" actions={imageActions}>
        <ImageList
          handleImageUpdate={handleUpdateImage}
          images={project.cartographies}
        />
      </Section>
    </div>
  );
};
