import React, { createContext, useContext, useEffect, useState } from "react";
import { saveLocal, readLocal } from "../utils/localStorageHelper";
const IntermediateContext = createContext(null);

const initial = {
  selectedIntermediateId: undefined,
  filters: {
    query: "",
    scopeList: [],
  },
};

export const IntermediateContextProvider = ({ children }) => {
  const [intermediateContext, setIntermediateContext] = useState(
    readLocal("crescendo-intermediate-datatable") || initial
  );

  useEffect(() => {
    if (intermediateContext) {
      saveLocal("crescendo-intermediate-datatable", intermediateContext);
    }
  }, [intermediateContext]);

  const handleSelectedIntermediate = (intermediate) => {
    setIntermediateContext({
      ...intermediateContext,
      selectedIntermediateId: intermediate?.id,
    });
  };

  const setIntermediateFilter = (key, value) => {
    setIntermediateContext({
      ...intermediateContext,
      filters: {
        ...intermediateContext.filters,
        [key]: value,
      },
    });
  };

  return (
    <IntermediateContext.Provider
      value={{
        intermediateContext,
        setIntermediateFilter,
        handleSelectedIntermediate,
      }}
    >
      {children}
    </IntermediateContext.Provider>
  );
};

export const useIntermediateContext = () => {
  return useContext(IntermediateContext);
};
