import { CONSTANTS } from "../ressources/CONSTANTS";

export const getCurrentReleaseNote = () => {
  try {
    return RELEASE_NOTES.find((rn) => rn.version === CONSTANTS.VERSION);
  } catch (error) {
    console.log(
      `Not release not has been found for version ${CONSTANTS.VERSION}`
    );
    return null;
  }
};

const RELEASE_NOTES = [
  {
    version: "2.4.0",
    date: "08/10/2024",
    title: null,
    intro: "Nouvelle version mineure pour l'application Crescendo Imvest !",
    newFeatures: [
      "Ajout du module de report de bug",
      "Ajout du module de release note",
      "Refonte complète de la navigation",
      'Ajout d\'un indicateur permettant de voir rapidement quelles transactions sont "en attente de validation" dans le tableau des investisseurs.',
      "Module de commentaire interne au niveau des investisseurs et des intermédiaires.",
      "Nouveau mode d'affichage pour le menu latéral.",
      "Améliorations visuelles et fonctionnelles diverses.",
      "Modification du système de filtrage et de tri des tableaux.",
      "Augmentation signifiicative des performances de l'application, notamment du chargement du tableau d'investisseurs.",
    ],
    fixes: ["L'utilisation sur mobile est désormais bloquée."],
  },
];
