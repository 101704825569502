import React, { createContext, useContext, useEffect, useState } from "react";
import { saveLocal, readLocal } from "../utils/localStorageHelper";
const BeneficiaryContext = createContext(null);

const initial = {
  selectedBeneficiaryId: undefined,
  selectedProjectId: undefined,
  filters: {
    query: "",
  },
};

export const BeneficiaryContextProvider = ({ children }) => {
  const [beneficiaryContext, setBeneficiaryContext] = useState(
    readLocal("crescendo-beneficiary-datatable") || initial
  );

  useEffect(() => {
    if (beneficiaryContext) {
      saveLocal("crescendo-beneficiary-datatable", beneficiaryContext);
    }
  }, [beneficiaryContext]);

  const handleCurrentView = (view) => {
    setBeneficiaryContext({
      ...beneficiaryContext,
      currentView: view,
    });
  };

  const handleSelectedBeneficiary = (beneficiary) => {
    setBeneficiaryContext({
      ...beneficiaryContext,
      selectedBeneficiaryId: beneficiary?.id,
      selectedProjectId: undefined,
    });
  };

  const handleSelectedProject = (project) => {
    setBeneficiaryContext({
      ...beneficiaryContext,
      selectedProjectId: project?.id,
    });
  };

  const setBeneficiaryFilter = (key, value) => {
    setBeneficiaryContext({
      ...beneficiaryContext,
      filters: {
        ...beneficiaryContext.filters,
        [key]: value,
      },
    });
  };

  return (
    <BeneficiaryContext.Provider
      value={{
        beneficiaryContext,
        setBeneficiaryFilter,
        handleCurrentView,
        handleSelectedBeneficiary,
        handleSelectedProject,
      }}
    >
      {children}
    </BeneficiaryContext.Provider>
  );
};

export const useBeneficiaryContext = () => {
  return useContext(BeneficiaryContext);
};
