import React from "react";
import ImageItem from "../../components/Image/ImageItem";
import Data from "../../components/Display/Data";
import DataTag from "../../components/Display/DataTag";
import DocumentList from "../../components/DocumentList";
import Section from "../../components/Display/Section";
import ImageList from "../../components/ImageList";
import { CONSTANTS } from "../../ressources/CONSTANTS";

export const ResidenceDetail = ({
  residence,
  handleUpdateDocument,
  handleUpdateImage,
  handleResidenceUpdate,
  handleCreateImage,
  handleCreateDocument,
}) => {
  const informationActions = handleResidenceUpdate && [
    {
      onClick: handleResidenceUpdate,
      label: "Modifier les informations",
      icon: "pi-pencil",
    },
  ];

  const imageActions = handleCreateImage && [
    {
      onClick: handleCreateImage,
      label: "Ajouter une image",
      icon: "pi-plus",
    },
  ];

  const documentActions = handleCreateDocument && [
    {
      onClick: handleCreateDocument,
      label: "Ajouter un document",
      icon: "pi-plus",
    },
  ];

  return (
    <div className="main-content-container h-auto overflow-y-scroll">
      <Section label="Informations" actions={informationActions}>
        <div className="data-section-row">
          <Data key="name" label={"Nom"} value={residence?.name} large />
          <DataTag
            key="state"
            label={"Etat"}
            value={residence?.state}
            bgColor={
              CONSTANTS.RESIDENCE_STATES.find(
                (rs) => rs.label === residence?.state
              )?.color
            }
            rounded
          />
          <DataTag key="type" label={"Type"} value={residence?.type} rounded />
        </div>

        <div className="data-section-row">
          <Data
            key="address"
            label={"Adresse"}
            value={residence?.address}
            large
          />
          <Data
            key="city"
            label={"Ville"}
            value={residence?.city ? residence.city.name : ""}
          />
          <Data
            key="zip"
            label={"Code postal"}
            value={residence?.city ? residence.city.zip_code : ""}
          />
        </div>

        <div className="data-section-row">
          <Data
            key="totalBudget"
            label={"Budget total"}
            value={residence?.total_budget}
            type="currency"
            large
          />
          <Data
            key="collectedFund"
            label={"Fonds collectés"}
            value={residence?.collected_fund}
            type="currency"
            large
          />
        </div>
      </Section>

      <div className="data-section-row">
        <Data
          key="totalSeniorHousingNb"
          label={"Nb total de logements seniors"}
          value={residence?.total_senior_housing_nb}
          large
        />
        <Data
          key="totalJuniorHousingNb"
          label={"Nb total de logements jeunes"}
          value={residence?.total_junior_housing_nb}
          large
        />
        <Data
          key="occupiedSeniorHousingNb"
          label={"Nb de logements occupés seniors"}
          value={residence?.occupied_senior_housing_nb}
          large
        />
        <Data
          key="occupiedJuniorHousingNb"
          label={"Nb de logements occupés jeunes"}
          value={residence?.occupied_junior_housing_nb}
          large
        />
        <Data
          key="totalSeniorInhabitantNb"
          label={"Nb total de résidents seniors"}
          value={residence?.total_senior_inhabitant_nb}
          large
        />
        <Data
          key="totalJuniorInhabitantNb"
          label={"Nb total de résidents jeunes"}
          value={residence?.total_junior_inhabitant_nb}
          large
        />
        <Data
          key="occupiedSeniorInhabitantNb"
          label={"Nb de résidents actuels seniors"}
          value={residence?.occupied_senior_inhabitant_nb}
          large
        />
        <Data
          key="occupiedJuniorInhabitantNb"
          label={"Nb de résidents actuels jeunes"}
          value={residence?.occupied_junior_inhabitant_nb}
          large
        />
      </div>

      <div className="data-section-row">
        <Data
          key="closingDate"
          label={"Closing financement"}
          value={residence?.funding_end_dt}
          large
          type="date"
        />
        <Data
          key="signatureDate"
          label={"Signature terrain"}
          value={residence?.land_signature_dt}
          large
          type="date"
        />
        <Data
          key="permitDate"
          label={"Dépôt permis de construire"}
          value={residence?.building_permit_deposit_dt}
          large
          type="date"
        />
        <Data
          key="constructionStartDate"
          label={"Début du chantier"}
          value={residence?.construction_start_dt}
          large
          type="date"
        />
        <Data
          key="constructionEndDate"
          label={"Fin du chantier"}
          value={residence?.construction_end_dt}
          large
          type="date"
        />
        <Data
          key="openingDate"
          label={"Ouverture"}
          value={residence?.inauguration_dt}
          large
          type="date"
        />
      </div>

      <div className="data-section-row">
        <Data
          key="internalNotes"
          label={"Notes internes"}
          value={residence?.internal_notes}
          large
        />
        <Data
          key="publicDescription"
          label={"Description publique"}
          value={residence?.description}
          large
        />
      </div>

      <Section label="Photos" actions={imageActions}>
        <ImageList
          handleImageUpdate={handleUpdateImage}
          images={residence?.photos || []}
        />
      </Section>

      <Section label="Documents" actions={documentActions}>
        <DocumentList
          handleExistingDocument={handleUpdateDocument}
          requiredDocuments={[]}
          documents={residence?.documents || []}
        />
      </Section>
    </div>
  );
};
