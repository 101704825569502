import React, { useState, useEffect } from "react";
import TitlePage from "../../../components/TitlePage";
import { useAccountContext } from "../../../contexts/AccountContext";
import Button from "../../../components/Button";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { COLORS } from "../../../ressources/STYLING";
import Data from "../../../components/Display/Data";
import DataTag from "../../../components/Display/DataTag";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import ManagePassword from "../../../modules/managers/ManagePassword";
import { privateFetch } from "../../../utils/apiHelper";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { saveSession } from "../../../utils/sessionHelper";
import { useNavigate } from "react-router-dom";
import IntermediateDocuments from "../../../modules/user/IntermediateDocuments";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Section from "../../../components/Display/Section";

function AccountPage({ hidden }) {
  const { accountContext, isIntermediate } = useAccountContext();
  const navigate = useNavigate();
  const [passwordModuleOpen, setPasswordModuleOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const updatePassword = async (passwordForm) => {
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/password/`,
      "Votre mot de passe actuel est erroné. Veuillez réessayer",
      "Le mot de passe a bien été modifié.",
      JSON.stringify(passwordForm)
    ).then((res) => {
      setPasswordModuleOpen(false);
      if (res) {
        setLogoutDialogOpen(true);
        setTimeout(() => {
          saveSession(null);
          navigate("/");
        }, 4000);
      }
    });
  };

  const informationActions = [
    {
      label: "Modifier mon mot de passe",
      icon: "pi pi-lock",
      onClick: () => {
        setPasswordModuleOpen(true);
      },
    },
  ];

  const mainContent = (
    <div className="main-content-container">
      <Section label="Informations" actions={informationActions}>
        <div className="data-section-row">
          <Data
            key="alias"
            label={"Nom d'affichage"}
            value={accountContext.alias}
            large
          />
          <Data
            key="email"
            label={"Email"}
            value={accountContext.email}
            large
          />
          <DataTag
            key="type"
            label={"Type de compte"}
            value={
              CONSTANTS.ACCOUNT_TYPES.find(
                (at) => at.value === accountContext.type
              ).label
            }
            bgColor={
              CONSTANTS.ACCOUNT_TYPES.find(
                (at) => at.value === accountContext.type
              ).color
            }
            rounded
          />
          <Data
            key="creationDt"
            label={"Date de création"}
            value={accountContext.creation_date}
            type="date"
          />
        </div>
      </Section>
      {isIntermediate && <IntermediateDocuments />}
    </div>
  );

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Header */}
      <BreadCrumb
        items={[
          {
            label: "Mon compte",
            isActive: true,
          },
        ]}
      />
      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper">{mainContent}</div>

      <ManagePassword
        isOpen={passwordModuleOpen}
        handleClose={() => {
          setPasswordModuleOpen(false);
        }}
        updatePassword={updatePassword}
      />

      <Dialog
        header="Vous allez être déconnecté..."
        visible={logoutDialogOpen}
        style={{ width: "30vw" }}
        draggable={false}
        closable={false}
      >
        <div className="dialog-container">
          <div style={{ width: 30, marginRight: 15 }}>
            <ProgressSpinner style={{ width: "30px", height: "30px" }} />
          </div>
          <p>Veuillez vous reconnecter avec votre nouveau mot de passe</p>
        </div>
      </Dialog>
    </div>
  );
}

export default AccountPage;
