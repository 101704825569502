import React, { useState, useRef } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { applyCurrencyFormat } from "../../utils/datatableHelper";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Tag from "../../components/Tag";
import { useResidenceContext } from "../../contexts/ResidenceContext";
import Search from "../../components/Datatable/Search";
import { Button } from "primereact/button";

export const ResidenceDatatable = ({
  data,
  selectItem,
  handleCreateResidence,
}) => {
  const exportRef = useRef(null);
  const { residenceContext, setResidenceFilter } = useResidenceContext();

  const handleFilter = (key, value) => {
    setResidenceFilter(key, value);
  };

  const stateBodyTemplate = (rowData) => {
    if (!rowData.state) {
      return null;
    }
    return (
      <div className="cell-container">
        <Tag
          value={rowData.state}
          bgColor={
            CONSTANTS.RESIDENCE_STATES.find((rs) => rs.label === rowData.state)
              .color
          }
        />
      </div>
    );
  };

  const filters = (
    <Search
      placeholder="Filtrer par nom"
      handleSearch={(value) => handleFilter("query", value)}
      value={residenceContext.filters.query}
    />
  );

  const actions = (
    <div className="flex flex-row gap-x-3">
      <Button
        key={"csv"}
        size={"small"}
        onClick={() => exportRef.current.exportCSV()}
        icon={"pi pi-cloud-download"}
        label={"Exporter"}
        outlined
      />
      {handleCreateResidence && (
        <Button
          key={"create"}
          size={"small"}
          onClick={handleCreateResidence}
          icon={"pi pi-plus"}
          label={"Nouveau"}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row justify-between w-full">
        {filters}
        {actions}
      </div>
      <DataTable
        ref={exportRef}
        value={data}
        removableSort
        emptyMessage={"Pas de résidence à afficher"}
        scrollable={true}
        selectionMode="single"
        onRowSelect={(e) => selectItem(e.data)}
        exportFilename={`export_residences_${moment().format("DD/MM/YYYY")}`}
        csvSeparator=";"
      >
        <Column
          sortable
          className="font-bold"
          field="name"
          header="Nom"
        ></Column>
        <Column
          sortable
          field="state"
          header="État"
          body={stateBodyTemplate}
        ></Column>
        <Column field="city.name" header="Ville"></Column>
        <Column field="city.zip_code" header="Code postal"></Column>

        <Column
          dataType="numeric"
          sortable
          field="total_budget"
          header="Budget Total"
          body={(rowData) => applyCurrencyFormat(rowData.total_budget)}
        ></Column>

        <Column
          dataType="numeric"
          sortable
          field="collected_fund"
          header="Fonds collectés"
          body={(rowData) => applyCurrencyFormat(rowData.collected_fund)}
        ></Column>
      </DataTable>
    </div>
  );
};
