import React, { useRef } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import {
  applyAreaFormat,
  applyCurrencyFormat,
  applyDateFormat,
} from "../../utils/datatableHelper";
import Tag from "../../components/Tag";
import Search from "../../components/Datatable/Search";
import { useBeneficiaryContext } from "../../contexts/BeneficiaryContext";
import { Button } from "primereact/button";

export const BeneficiaryDatatable = ({
  loading,
  data,
  selectItem,
  handleCreateBeneficiary,
}) => {
  const { beneficiaryContext, setBeneficiaryFilter } = useBeneficiaryContext();
  const exportRef = useRef(null);

  const handleFilter = (key, value) => {
    setBeneficiaryFilter(key, value);
  };

  const projectBodyTemplate = (rowData) => {
    if (!rowData.project)
      return <span style={{ fontStyle: "italic" }}>{"Aucun"}</span>;
    return <Tag value={rowData.project.reference} />;
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totaux" />
        <Column footer={`${data.total_beneficiary} bénéficiaires`} />
        <Column footer={`(${data.total_project} projets)`} />
        <Column footer={`${applyAreaFormat(data.total_surface)}`} />
        <Column footer={`${applyCurrencyFormat(data.total_project_amount)}`} />
        <Column />
        <Column />
      </Row>
    </ColumnGroup>
  );

  const filters = (
    <Search
      placeholder="Filtrer par nom, prénom"
      handleSearch={(value) => handleFilter("query", value)}
      value={beneficiaryContext.filters.query}
    />
  );

  const actions = (
    <div className="flex flex-row gap-x-3">
      <Button
        key={"csv"}
        size={"small"}
        onClick={() => exportRef.current.exportCSV()}
        icon={"pi pi-cloud-download"}
        label={"Exporter"}
        outlined
      />
      {handleCreateBeneficiary && (
        <Button
          key={"create"}
          size={"small"}
          onClick={handleCreateBeneficiary}
          icon={"pi pi-plus"}
          label={"Nouveau bénéficiaire"}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row justify-between w-full">
        {filters}
        {actions}
      </div>
      <DataTable
        ref={exportRef}
        value={data.beneficiary_list}
        removableSort
        emptyMessage={"Pas de bénéficiaire à afficher"}
        scrollable={true}
        loading={loading}
        selectionMode="single"
        onRowSelect={(e) => selectItem(e.data)}
        exportFilename={`export_beneficiaire_${moment().format("DD/MM/YYYY")}`}
        csvSeparator=";"
        footerColumnGroup={footerGroup}
      >
        <Column
          sortable
          className="font-bold"
          field="name"
          header="Nom"
        ></Column>
        <Column
          sortable
          className="font-bold"
          field="first_name"
          header="Prénom"
        ></Column>
        <Column
          field="project"
          header="Projet"
          body={projectBodyTemplate}
        ></Column>
        <Column
          dataType="numeric"
          sortable
          field="total_surface"
          header="Surface totale"
          body={(rowData) => applyAreaFormat(rowData.total_surface)}
        ></Column>
        <Column sortable field="city" header="Ville"></Column>
        <Column sortable field="region" header="Région"></Column>
        <Column
          sortable
          field="creation_date"
          header="Date de création"
          body={(rowData) => applyDateFormat(rowData.creation_date)}
        ></Column>
      </DataTable>
    </div>
  );
};
