import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FormInput from "../Form/FormInput";
import { InputTextarea } from "primereact/inputtextarea";

function ManageComment({ isOpen, handleClose, createComment, loading }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const onValidate = () => {
    const commentForm = {
      title,
      content,
    };
    setTitle("");
    setContent("");
    createComment(commentForm);
  };

  const titleIsValid = title.length > 2 && title.length <= 200;
  const contentIsValid = content.length > 2;
  const formIsValid = titleIsValid && contentIsValid;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={"validate"}
        label={"Créer le commentaire"}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
        className="w-full"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{"Créer un commentaire"}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput
            key="title"
            label="Titre"
            required
            isValid={titleIsValid}
            tooltipMessage={"Champs obligatoire (max. 200 caractères)"}
          >
            <InputText
              value={title}
              placeholder="Veuillez saisir le titre du commentaire"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>

          <FormInput
            key="content"
            label={"Contenu"}
            required
            isValid={contentIsValid}
          >
            <InputTextarea
              rows={5}
              autoResize
              placeholder="Veuillez saisir le contenu du commentaire"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageComment;
