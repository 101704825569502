import React, {createContext, useContext, useState, useEffect, useMemo} from "react";

const AccountContext = createContext(null);

export const AccountContextProvider = ({ children }) => {
  const [accountContext, setAccountContext] = useState(null);

  const isLoggedIn = useMemo(() => accountContext !== null, [accountContext]);
  const isAdmin = useMemo(() => accountContext?.type === 'ADMIN', [accountContext]);
  const isIntermediate = useMemo(() => accountContext?.type === 'INTERMEDIATE', [accountContext]);

  return (
    <AccountContext.Provider
      value={{ accountContext, setAccountContext, isAdmin, isIntermediate, isLoggedIn }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => {
  return useContext(AccountContext);
};
