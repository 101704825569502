import React from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { applyCurrencyFormat } from "../../utils/datatableHelper";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Tag from "../../components/Tag";
import { MEDIAS } from "../../ressources/MEDIAS";
import { useNavigationContext } from "../../contexts/NavigationContext";

export const TransactionDatatable = ({
  selectTransaction,
  data,
  exportRef,
  exportFileName,
  showInvestorName,
}) => {
  const { getScopeFromId, getAuthorizedScopeList } = useAuthorizationsContext();
  const { navigateToIntermediate } = useNavigationContext();
  const executionDateTemplate = (rowData) => {
    return moment(rowData.execution_date).format("DD/MM/YYYY");
  };

  const scopesBodyTemplate = (rowData) => {
    return (
      <Tag
        value={getScopeFromId(rowData.linked_scope).label}
        bgColor={"bg-" + getScopeFromId(rowData.linked_scope).main_color}
        rounded
      />
    );
  };

  const intermediateBodyTemplate = (rowData) => {
    if (!rowData.intermediate)
      return <span className="italic text-gray-400">Non renseigné</span>;
    return (
      <div className="flex gap-x-2">
        <Tag value={rowData.intermediate.name} icon={MEDIAS.briefcaseWhite} />
        {rowData.intermediate_account && (
          <Tag
            icon={MEDIAS.userWhite}
            value={rowData.intermediate_account.alias}
          />
        )}
      </div>
    );
  };

  const natureBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          CONSTANTS.TRANSACTION_NATURE_TYPES.find(
            (nt) => nt.value === rowData.nature
          ).label
        }
        bgColor={
          CONSTANTS.TRANSACTION_NATURE_TYPES.find(
            (nt) => nt.value === rowData.nature
          ).color
        }
        rounded
      />
    );
  };

  const stateBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          CONSTANTS.TRANSACTION_STATUS.find((ts) => ts.value === rowData.status)
            .label
        }
        bgColor={
          CONSTANTS.TRANSACTION_STATUS.find((ts) => ts.value === rowData.status)
            .color
        }
        rounded
      />
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totaux" />
        {showInvestorName && <Column />}
        <Column />
        <Column footer={`${data?.transactions?.length} transations`} />
        <Column footer={`${applyCurrencyFormat(data.balance)}`} />
        <Column footer={`${data.share_balance}`} />
        <Column />
        <Column />
        <Column />
        {getAuthorizedScopeList().length > 1 && <Column />}
      </Row>
    </ColumnGroup>
  );

  return (
    <DataTable
      ref={exportRef}
      value={data.transactions}
      emptyMessage={"Pas de transaction à afficher"}
      selectionMode="single"
      onRowSelect={(e) => selectTransaction(e.data)}
      exportFilename={exportFileName}
      csvSeparator=";"
      footerColumnGroup={footerGroup}
    >
      {showInvestorName && (
        <Column
          header="Investisseur"
          field="investor.name,investor.first_name"
          className="font-bold"
          exportField={(rowData) =>
            `${rowData.investor.first_name} ${rowData.investor.name}`
          }
          body={(rowData) =>
            `${rowData.investor.first_name} ${rowData.investor.name}`
          }
        ></Column>
      )}

      <Column field="nature" header="Nature" body={natureBodyTemplate}></Column>
      <Column field="status" header="État" body={stateBodyTemplate}></Column>
      <Column field="transaction_type.label" header="Type"></Column>
      <Column
        field="amount"
        header="Montant"
        body={(rowData) => applyCurrencyFormat(rowData.amount)}
      ></Column>
      <Column dataType="numeric" field="share_nb" header="Nb"></Column>
      <Column
        body={executionDateTemplate}
        field="execution_date"
        header="Date"
        dataType="date"
      ></Column>
      <Column
        field="intermediate"
        header="Intermédiaire"
        body={intermediateBodyTemplate}
      ></Column>
      {getAuthorizedScopeList().length > 1 && (
        <Column
          field="linked_scope"
          header="Périmètre"
          body={scopesBodyTemplate}
        ></Column>
      )}
      <Column field="id" header="ID"></Column>
    </DataTable>
  );
};
