export const DIMENSIONS = {
  screenHeight: "100vh",
  screenWidth: "100vw",

  navBarWidthOpen: "15vw",
  navBarWidthClose: "54px",

  navBarHeight: 0,

  pageWidth: "88vw",
  pageHeight: "100vh",

  tabWidthNavbarOpen: "86vw",
  tabWidthNavbarClose: "92vw",
  tabHeight: "90vh",

  modalWidth: "91vw",
  modalHeight: "96vh",
};

export const COLORS = {
  whiteBackground: "#EDF0F4",
  white: "#FFFFFF",
  blueBackground: "#005A78",
  blackBackground: "#212121",

  startGradientTerrafine: "#008f6c",
  endGradientTerrafine: "#028D6C",

  startGradientResidens: "#B0255F",
  endGradientResidens: "#DC727D",

  startGradientSettings: "#005A78",
  endGradientSettings: "#0083B0",

  orangeButton: "#F6A414",
  greenButton: "#61B345",
  greenModal: "#037868",
  purpleModal: "#ba3766",
  redButton: "#E13834",
  greyButton: "#707070",
};
