import React, { useState, useEffect, useRef } from "react";
import { COLORS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { privateFetch } from "../../../utils/apiHelper";
import TitlePage from "../../../components/TitlePage";
import Button from "../../../components/Button";
import { UserDatatable } from "../../../modules/user/UserDatatable";
import { useAccountContext } from "../../../contexts/AccountContext";
import { confirmDialog } from "primereact/confirmdialog";

import "../../Pages.scss";
import ManageUser from "../../../modules/managers/ManageUser";
import ManageAuthorizations from "../../../modules/managers/ManageAuthorizations";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";

function UserPage({ hidden }) {
  const datatablelRef = useRef(null);
  const { accountContext } = useAccountContext();
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState(null);
  const [userModuleOpen, setUserModuleOpen] = useState(false);
  const [authorizationModuleOpen, setAuthorizationModuleOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserList();
  }, []);

  useEffect(() => {
    user && setUser(userList.find((u) => u.id === user.id));
  }, [userList]);

  const handleUserSelection = (targetUser) => {
    if (!user || targetUser.id !== user.id) {
      setUser(targetUser);
    }
  };

  const fetchUserList = async () => {
    await privateFetch("GET", `/cu/${accountContext.id}/cu/`).then((res) => {
      if (res) setUserList(res);
    });
  };

  const createUser = async (userForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/cu/`,
      "Une erreur est survenue lors de la création de l'utilisateur",
      "L'utilisateur a bien été créé. Un email contenant son mot de passe lui a été envoyé.",
      JSON.stringify(userForm)
    ).then((response) => {
      if (response) {
        setUserModuleOpen(false);
        setLoading(false);
        fetchUserList();
      }
    });
  };

  const deleteUser = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/cu/${user.id}/`,
      "Une erreur est survenue lors de la suppression de l'utilisateur.",
      "L'utilisateur a bien été supprimé."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setUser(undefined);
        fetchUserList();
      }
    });
  };

  const updateAuthorization = async (authorization) => {
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/cu/${user.id}/authorization/${authorization.id}/`,
      "Une erreur est survenue lors de la modification de l'autorisation",
      "La modification d'autorisation a bien été enregistrée",
      JSON.stringify(authorization)
    ).then((response) => {
      if (response) {
        fetchUserList();
      }
    });
  };

  const confirmUserDeletion = () => {
    setUserModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer l'utilisateur ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteUser(),
    });
  };

  const breadcrumbItems = () => {
    const items = [
      {
        label: "Utilisateurs",
        isActive: true,
      },
    ];
    user &&
      items.push({
        label: `${user.alias}`,
        isActive: true,
      });
    return items;
  };

  const mainContent = () => (
    <div className="p-4 w-full overflow-y-hidden">
      <div className="full-page-datatable">
        <UserDatatable
          selectedUser={user}
          data={userList}
          selectItem={handleUserSelection}
          handleUpdateUser={() => setAuthorizationModuleOpen(true)}
          handleCreateUser={() => {
            setUser(undefined);
            setUserModuleOpen(true);
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Breadcrumb */}
      <BreadCrumb items={breadcrumbItems()} />

      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper">
        {mainContent()}
      </div>

      {/* Modules */}
      <ManageUser
        loading={loading}
        isOpen={userModuleOpen}
        handleClose={() => setUserModuleOpen(false)}
        handleCreation={createUser}
      />

      <ManageAuthorizations
        user={user}
        isOpen={authorizationModuleOpen}
        handleClose={() => setAuthorizationModuleOpen(false)}
        handleAuthorizationUpdate={updateAuthorization}
        handleDelete={confirmUserDeletion}
      />
    </div>
  );
}

export default UserPage;
