import React from "react";
import { formatDate } from "../../utils/dateHelper";
import Tag from "../Tag";

function CommentItem({ comment }) {
  return (
    <div className="w-full flex flex-col gap-y-2 pt-2 pb-3 px-3 rounded bg-slate-100">
      <div className="w-full flex flex-row justify-between items-center">
        <h4>{comment.title || "Commentaire sans titre"}</h4>
      </div>
      <div className="w-full flex flex-col gap-y-3">
        <p
          className="text-sm"
          dangerouslySetInnerHTML={{ __html: comment.content }}
        />
        <div className="flex flex-row w-full justify-between items-center pt-3">
          <Tag
            value={comment.author.alias}
            // onClick={() => console.log(comment.author.alias)}
          />
          <span className="text-sm italic text-slate-500">
            {formatDate(comment.creation_date)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CommentItem;
