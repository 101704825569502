import React, { useMemo } from "react";
import { IntermediateAccountDatatable } from "./IntermediateAccountDatatable";
import Data from "../../components/Display/Data";
import DataMultipleTags from "../../components/Display/DataMultipleTags";
import { IntermediateTransactionDatatable } from "./IntermediateTransactionDatatable";
import DocumentList from "../../components/DocumentList";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";

import { CONSTANTS } from "../../ressources/CONSTANTS";
import Section from "../../components/Display/Section";

function IntermediateDetail({
  intermediate,
  handleUpdateAccount,
  handleUpdateDocument,
  handleIntermediateUpdate,
  handleCreateAccount,
  handleCreateDocument,
}) {
  const { getScopeFromId } = useAuthorizationsContext();

  const handleExistingDocument = (d) => {
    handleUpdateDocument(d, d.type);
  };

  const handleMissingDocument = (d) => {
    handleUpdateDocument(undefined, d.type);
  };

  const scopeTags = useMemo(
    () =>
      intermediate?.linked_scopes.map((lsId) => ({
        value: getScopeFromId(lsId).label,
        bgColor: "bg-" + getScopeFromId(lsId).main_color,
        rounded: true,
      })),
    [intermediate]
  );

  const informationActions = handleIntermediateUpdate && [
    {
      onClick: handleIntermediateUpdate,
      label: "Modifier les informations",
      icon: "pi-pencil",
    },
  ];

  const accountActions = handleCreateAccount && [
    {
      onClick: handleCreateAccount,
      label: "Ajouter un compte",
      icon: "pi-plus",
    },
  ];

  const documentActions = handleCreateDocument && [
    {
      onClick: handleCreateDocument,
      label: "Ajouter un document",
      icon: "pi-plus",
    },
  ];

  if (!intermediate) return null;

  return (
    <div className="main-content-container">
      <Section label="Informations" actions={informationActions}>
        <div className="data-section-row">
          <Data key="name" label={"Nom"} value={intermediate?.name} large />
          <Data
            key="maxFeeRate"
            label={"Taux max."}
            value={intermediate?.max_fee_rate}
            type={"percentage"}
          />
          <DataMultipleTags label={"Périmètres"} tags={scopeTags || []} large />
          <Data key="id" label={"ID"} value={intermediate?.id} large />
        </div>

        <div className="data-section-row">
          <Data key="email" label={"Email"} value={intermediate?.email} large />
          <Data key="phone" label={"Téléphone"} value={intermediate?.phone} />
          <Data
            key="address"
            label={"Adresse"}
            value={intermediate?.address}
            large
          />
        </div>
      </Section>

      <Section label="Documents" actions={documentActions}>
        <DocumentList
          handleMissingDocument={handleMissingDocument}
          handleExistingDocument={handleExistingDocument}
          requiredDocuments={CONSTANTS.INTERMEDIATE_DOCUMENTS}
          documents={intermediate?.documents}
        />
      </Section>

      <Section label="Comptes associés" actions={accountActions}>
        <IntermediateAccountDatatable
          data={intermediate?.accounts}
          selectItem={handleUpdateAccount}
        />
      </Section>

      <Section label="Transactions associées">
        <IntermediateTransactionDatatable data={intermediate?.transactions} />
      </Section>
    </div>
  );
}

export default IntermediateDetail;
