import React from "react";
import { Sidebar } from "primereact/sidebar";
import { Checkbox } from "primereact/checkbox";
import TitlePage from "../../components/TitlePage";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import "./ManageItem.scss";
import { Button } from "primereact/button";

function ManageAuthorizations({
  isOpen,
  handleClose,
  handleAuthorizationUpdate,
  handleDelete,
  user,
}) {
  const onUpdateAuthorization = (authorization, targetType) => {
    handleAuthorizationUpdate({
      ...authorization,
      type: targetType,
    });
  };

  const footerButtons = (
    <Button
      key={"validate"}
      label={`Supprimer le compte`}
      onClick={handleDelete}
      severity="danger"
      outlined
    />
  );

  const isReadWrite = () => {
    return user.type === "EMPLOYEE";
  };

  if (!user) return null;
  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<TitlePage text={`${user.alias}`} />}
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="module-form-container">
          {user.scopes.map((s) => (
            <div key={s.id} className="form-section">
              <div
                className="form-section-title"
                style={{ color: s.main_color }}
              >
                {`Autorisations ${s.label}`}
              </div>
              {s.authorizations.map((a) => (
                <div key={a.id} className="form-item-container">
                  <span>{CONSTANTS.SUBAPP_LABELS[s.name][a.subapp]}</span>
                  <div className="checkbox-container">
                    {CONSTANTS.AUTHORIZATION_TYPES.map((at, i) => (
                      <div key={i} className="checkbox-input">
                        <Checkbox
                          inputId={`${at.value}-${a.id}`}
                          disabled={!isReadWrite()}
                          name={at.label}
                          value={at.value}
                          onChange={(e) =>
                            onUpdateAuthorization(a, e.target.value)
                          }
                          checked={a.type === at.value}
                        />
                        <label htmlFor={`${at.value}-${a.id}`}>
                          {at.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
          {user.type === "ADMIN" && (
            <span className="form-information">
              Les autorisations ne sont pas modifiables pour les comptes
              administrateurs.
            </span>
          )}
          {user.type === "INTERMEDIATE" && (
            <span className="form-information">
              Les autorisations ne sont pas modifiables pour les comptes
              intermédiaires.
            </span>
          )}
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageAuthorizations;
