import React, { createContext, useContext, useEffect, useState } from "react";
import { saveLocal, readLocal } from "../utils/localStorageHelper";
const ResidenceContext = createContext(null);

const initial = {
  selectedResidenceId: undefined,
  filters: {
    query: "",
  },
};

export const ResidenceContextProvider = ({ children }) => {
  const [residenceContext, setResidenceContext] = useState(
    readLocal("crescendo-residence-datatable") || initial
  );

  useEffect(() => {
    if (residenceContext) {
      saveLocal("crescendo-residence-datatable", residenceContext);
    }
  }, [residenceContext]);

  const handleSelectedResidence = (residence) => {
    setResidenceContext({
      ...residenceContext,
      selectedResidenceId: residence?.id,
    });
  };

  const setResidenceFilter = (key, value) => {
    setResidenceContext({
      ...residenceContext,
      filters: {
        ...residenceContext.filters,
        [key]: value,
      },
    });
  };

  return (
    <ResidenceContext.Provider
      value={{
        residenceContext,
        setResidenceFilter,
        handleSelectedResidence,
      }}
    >
      {children}
    </ResidenceContext.Provider>
  );
};

export const useResidenceContext = () => {
  return useContext(ResidenceContext);
};
