import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Tag from "../../components/Tag";
import { useUserContext } from "../../contexts/UserContext";
import Search from "../../components/Datatable/Search";
import { Button } from "primereact/button";

export const UserDatatable = ({
  data,
  selectItem,
  handleCreateUser,
  handleUpdateUser,
  selectedUser,
}) => {
  const { userContext, setUserFilter } = useUserContext();

  const handleFilter = (key, value) => {
    setUserFilter(key, value);
  };

  const typeBodyTemplate = ({ type }) => {
    const currentType = CONSTANTS.ACCOUNT_TYPES.find((at) => at.value === type);
    return (
      <div className="cell-container">
        <Tag value={currentType.label} bgColor={currentType.color} rounded />
      </div>
    );
  };

  const authorizationBadyTemplate = (scope) => {
    return (
      <div className="cell-container row-gap-xs">
        {scope.authorizations.map((auth) => (
          <Tag
            key={auth.id}
            value={CONSTANTS.SUBAPP_LABELS[scope.name][auth.subapp]}
            bgColor={CONSTANTS.AUTH_INFO[auth.type].bgColor}
            icon={CONSTANTS.AUTH_INFO[auth.type].icon}
            rounded
          />
        ))}
      </div>
    );
  };

  const filters = (
    <Search
      placeholder="Filtrer par nom"
      handleSearch={(value) => handleFilter("query", value)}
      value={userContext.filters.query}
    />
  );

  const actions = (
    <div className="flex flex-row gap-x-3">
      <Button
        key={"create"}
        size={"small"}
        onClick={handleCreateUser}
        icon={"pi pi-plus"}
        label={"Nouvel utilisateur"}
        outlined
      />
      {selectedUser && (
        <Button
          key={"update"}
          size={"small"}
          onClick={handleUpdateUser}
          icon={"pi pi-pen"}
          label={`Modifier les autorisations de ${selectedUser.alias}`}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row justify-between w-full">
        {filters}
        {actions}
      </div>
      <DataTable
        value={data}
        removableSort
        scrollable={true}
        emptyMessage={"Pas d'utilisateur à afficher"}
        paginator
        rows={25}
        rowsPerPageOptions={[25, 50, 100]}
        filterDisplay="row"
        selectionMode="single"
        onRowSelect={(e) => selectItem(e.data)}
      >
        <Column
          sortable
          field="type"
          header="Rôle"
          body={typeBodyTemplate}
        ></Column>
        <Column
          sortable
          className="font-bold"
          field="alias"
          header="Nom & prénom"
        ></Column>
        <Column sortable field="email" header="Email"></Column>
        <Column
          sortable
          field="scope"
          header="Autorisations Terrafine"
          body={(rowData) =>
            authorizationBadyTemplate(
              rowData.scopes.find((s) => s.name === "terrafine")
            )
          }
        ></Column>
        <Column
          sortable
          field="scope"
          header="Autorisations Résid'ens"
          body={(rowData) =>
            authorizationBadyTemplate(
              rowData.scopes.find((s) => s.name === "residens")
            )
          }
        ></Column>
      </DataTable>
    </div>
  );
};
