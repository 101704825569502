import React from "react";
import { DashboardTransactionDatatable } from "./DashboardTransactionDatatable";
import { DashboardProjectDatatable } from "./DashboardProjectDatatable";

function DashboardDetail({ indicatorData }) {
  return (
    <div className="flex flex-col gap-y-5 p-5 bg-white rounded-lg shadow-md">
      <h4 className="!text-custom-blue">{indicatorData?.label}</h4>
      {indicatorData && indicatorData?.type === "PROJECT" ? (
        <DashboardProjectDatatable data={indicatorData} />
      ) : (
        <DashboardTransactionDatatable data={indicatorData} />
      )}
    </div>
  );
}

export default DashboardDetail;
