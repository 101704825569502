import React from "react";
import Section from "../Display/Section";
import CommentItem from "./CommentItem";

function CommentFeed({ commentList, handleCreateComment }) {
  const actions = handleCreateComment && [
    {
      onClick: handleCreateComment,
      label: "Nouveau",
      icon: "pi-plus",
    },
  ];

  return (
    <div className="h-full w-full border-l border-l-gray-300">
      <div className="main-content-container">
        <Section label="Commentaires" actions={actions}>
          {commentList?.length ? (
            commentList.map((comment) => (
              <CommentItem key={comment.id} comment={comment} />
            ))
          ) : (
            <p className="w-full text-sm text-slate-500	italic">
              Aucun commentaire
            </p>
          )}
        </Section>
      </div>
    </div>
  );
}

export default CommentFeed;
