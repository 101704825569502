import React, { useEffect, useState, useMemo } from "react";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import { SelectButton } from "primereact/selectbutton";
import KeyIndicatorButton from "../../../components/KeyIndicatorButton";
import DashboardDetail from "../../../modules/dashboard/DashboardDetail";
import { privateFetch } from "../../../utils/apiHelper";
import "../../Pages.scss";
import "../../Dashboard.scss";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";

function DashboardPage({ hidden }) {
  const { getAuthorizedScopeList } = useAuthorizationsContext();
  const { accountContext } = useAccountContext();

  const [indicatorList, setIndicatorList] = useState([]);
  const [indicator, setIndicator] = useState(null);
  const [indicatorData, setIndicatorData] = useState(null);
  const [activeScopeList, setActiveScopeList] = useState([]);

  useEffect(() => {
    setActiveScopeList(getAuthorizedScopeList());
  }, []);

  const [activeScopes, setActiveScopes] = useState(
    getAuthorizedScopeList().map((s) => s.name)
  );

  const scopeOptions = useMemo(() => {
    let options = [
      {
        label: "Tous les périmètres",
        value: getAuthorizedScopeList().map((s) => s.name),
      },
    ];
    getAuthorizedScopeList().forEach((s) => {
      options.push({
        label: s.label,
        value: [s.name],
      });
    });
    return options;
  }, []);

  useEffect(() => {
    fetchDashboardOverview();
  }, [activeScopes]);

  useEffect(() => {
    indicator && fetchSelectedIndicatorData();
  }, [indicator]);

  const fetchDashboardOverview = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/dashboard/overview/?scopes=${activeScopes.join(",")}`
    ).then((response) => {
      if (response) {
        setIndicatorList(response);
        response.length && setIndicator(response[0]);
      }
    });
  };

  const fetchSelectedIndicatorData = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/dashboard/${indicator.endpoint}?scopes=${activeScopes.join(",")}`
    ).then((response) => {
      if (response) setIndicatorData(response);
    });
  };

  const mainContent = (
    <div className="flex flex-col gap-y-5 pl-6 pr-5 pt-4 ">
      <SelectButton
        value={activeScopes}
        onChange={(e) => setActiveScopes(e.value)}
        options={scopeOptions}
      />
      <div className="flex flex-row gap-x-3">
        {indicatorList.map((ki) => (
          <KeyIndicatorButton
            key={ki.id}
            keyIndicator={ki}
            onClick={() => setIndicator(ki)}
            isSelected={indicator && ki.id === indicator.id}
          />
        ))}
      </div>
      <DashboardDetail indicatorData={indicatorData} />
    </div>
  );

  return (
    <div className="page-wrapper" hidden={hidden}>
      {/* Header */}
      <BreadCrumb
        items={[
          {
            label: "Dashboard",
            isActive: true,
          },
        ]}
      />
      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper">{mainContent}</div>
    </div>
  );
}

export default DashboardPage;

/*
  handleScrollToIndex = (
    targetIndex,
    selectedIndicator = this.state.selectedIndicator
  ) => {
    const { indicatorList } = this.state;
    const boudedTargetIndex = Math.min(
      Math.max(0, targetIndex),
      Math.max(indicatorList.length - 5, 5)
    );
    const targetXOffsetAsVW = DISABLE_HORIZONTAL_SCROLL
      ? 0
      : 13 * boudedTargetIndex;
    this.setState(
      {
        currentXOffsetAsVW: targetXOffsetAsVW,
        currentIndex: targetIndex,
        selectedIndicator,
      },
      () => this.fetchSelectedIndicatorData()
    );
  };
  */
