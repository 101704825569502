import React, { createContext, useContext, useEffect, useState } from "react";
import { saveLocal, readLocal } from "../utils/localStorageHelper";
const ArchiveContext = createContext(null);

const initial = {
  selectedTransactionId: undefined,
};

export const ArchiveContextProvider = ({ children }) => {
  const [archiveContext, setArchiveContext] = useState(
    readLocal("crescendo-archive-datatable") || initial
  );

  useEffect(() => {
    if (archiveContext) {
      saveLocal("crescendo-archive-datatable", archiveContext);
    }
  }, [archiveContext]);

  const handleCurrentView = (view) => {
    setArchiveContext({
      ...archiveContext,
      currentView: view,
    });
  };

  const handleSelectedTransaction = (transaction) => {
    setArchiveContext({
      ...archiveContext,
      selectedTransactionId: transaction?.id,
    });
  };

  return (
    <ArchiveContext.Provider
      value={{
        archiveContext,
        handleCurrentView,
        handleSelectedTransaction,
      }}
    >
      {children}
    </ArchiveContext.Provider>
  );
};

export const useArchiveContext = () => {
  return useContext(ArchiveContext);
};
