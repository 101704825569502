import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import React, { useMemo } from "react";

function Search({ placeholder, value, handleSearch }) {
  const isActive = useMemo(() => value && value.length, [value]);

  return (
    <IconField iconPosition="left">
      <InputIcon className="pi pi-search"> </InputIcon>
      <InputText
        placeholder={placeholder}
        className={`p-inputtext-sm !rounded-full ${
          isActive ? "active-custom-dropdown w-[300px]" : "w-[240px]"
        }`}
        onChange={(e) => handleSearch(e.target.value)}
        value={value}
      />
    </IconField>
  );
}

export default Search;
