import React, { createContext, useContext, useEffect, useState } from "react";
import { saveLocal, readLocal } from "../utils/localStorageHelper";
const UserContext = createContext(null);

const initial = {
  selectedUserId: undefined,
  filters: {
    query: "",
  },
};

export const UserContextProvider = ({ children }) => {
  const [userContext, setUserContext] = useState(
    readLocal("crescendo-user-datatable") || initial
  );

  useEffect(() => {
    if (userContext) {
      saveLocal("crescendo-user-datatable", userContext);
    }
  }, [userContext]);

  const handleSelectedUser = (user) => {
    setUserContext({
      ...userContext,
      selectedUserId: user?.id,
    });
  };

  const setUserFilter = (key, value) => {
    setUserContext({
      ...userContext,
      filters: {
        ...userContext.filters,
        [key]: value,
      },
    });
  };

  return (
    <UserContext.Provider
      value={{
        userContext,
        setUserFilter,
        handleSelectedUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
