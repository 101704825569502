import { getImage } from "../utils/imageHelper";

export const MEDIAS = {
  logoNode: getImage("logonode_white.png"),
  logoDiard: getImage("diard.png"),
  logoCrescendo: getImage("crescendo-logo.svg"),
  missingImage: getImage("missing_img.png"),
  test: getImage("new-user.png"),
  addUserWhite: getImage("icons/add-user-white.png"),
  addUserBlack: getImage("icons/add-user-black.png"),
  crossClose: getImage("icons/cross.png"),
  crossClosePurple: getImage("icons/cross_purple.png"),
  crossCloseGreen: getImage("icons/crossGreen.png"),
  crossAdd: getImage("icons/cross-1.png"),
  crossAddGreen: getImage("icons/cross-1Green.png"),
  doubleChevron: getImage("icons/doubleChevron.png"),
  editor: getImage("icons/editor.png"),
  editorBlack: getImage("icons/editor-black.png"),
  editorOrange: getImage("icons/orange-edit.png"),
  email: getImage("icons/email.png"),
  navIconProject: getImage("icons/nav-icon-project.png"),
  download: getImage("icons/download.png"),
  dashboard: getImage("icons/dashboard.png"),
  imageFile: getImage("icons/image-file.png"),
  imageFileOk: getImage("icons/image-file-ok.png"),
  imageFileDark: getImage("icons/image-file-dark.png"),
  news: getImage("icons/news.png"),
  noSee: getImage("icons/no-see.png"),
  see: getImage("icons/see.png"),
  addFile: getImage("icons/add-file.png"),
  addFileDark: getImage("icons/add-file-dark.png"),
  rightArrow: getImage("icons/right-arrow.png"),
  thirdParty: getImage("icons/third-party.png"),
  tick: getImage("icons/tick.png"),
  tick_green: getImage("icons/tick_green.png"),
  open: getImage("icons/open.png"),
  trash: getImage("icons/trash.png"),
  upload: getImage("icons/upload.png"),
  uploadDark: getImage("icons/upload-dark.png"),
  copy: getImage("icons/copy.png"),
  password: getImage("icons/password.png"),
  users: getImage("icons/users.png"),
  residence: getImage("icons/residence.png"),
  information: getImage("icons/information.png"),
  logout: getImage("icons/logout.png"),
  logoutDark: getImage("icons/logout-dark.png"),
  logoResidensWhite: getImage("icons/residens-logo-white.png"),
  exampleImage: getImage("example-image.jpg"),
  hyperlink: getImage("icons/hyperlink.png"),
  bug: getImage("icons/bug.png"),
  investment: getImage("icons/investment.png"),
  next: getImage("icons/next.png"),
  prev: getImage("icons/prev.png"),
  refund: getImage("icons/refund.png"),
  balance: getImage("icons/balance.png"),
  field: getImage("icons/field.png"),
  chevron: getImage("icons/chevron.png"),
  whiteRectangle: getImage("icons/white-rectangle.png"),
  generalLogo: getImage("logo_general.png"),
  signinImage: getImage("signin-image-min.png"),
  briefcase: getImage("icons/briefcase.png"),
  briefcaseBlack: getImage("icons/briefcase-black.png"),
  briefcaseWhite: getImage("icons/briefcase-white.png"),
  read: getImage("icons/eye.png"),
  write: getImage("icons/pencil.png"),
  forbidden: getImage("icons/forbidden.png"),
  userWhite: getImage("icons/user.png"),
  document: getImage("icons/document.png"),
  toolbox: getImage("icons/books.png"),
  account: getImage("icons/account.png"),
  archive: getImage("icons/archive.png"),
};
