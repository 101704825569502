import React, { createContext, useContext, useEffect, useState } from "react";
import { saveLocal, readLocal } from "../utils/localStorageHelper";
const InvestorContext = createContext(null);

const initial = {
  selectedInvestorId: undefined,
  selectedTransactionId: undefined,
  options: {
    offset: 0,
    rows: 50,
    page: 0,
  },
  sortOption: {
    sortField: "tp_balance",
    sortOrder: -1,
  },
  filters: {
    query: "",
    scopeList: [],
    intermediateList: [],
    stateList: [],
  },
};

export const InvestorContextProvider = ({ children }) => {
  const [investorContext, setInvestorContext] = useState(
    readLocal("crescendo-investor-datatable") || initial
  );

  useEffect(() => {
    if (investorContext) {
      saveLocal("crescendo-investor-datatable", investorContext);
    }
  }, [investorContext]);

  const handleCurrentView = (view) => {
    setInvestorContext({
      ...investorContext,
      currentView: view,
    });
  };

  const handleSelectedInvestor = (investor) => {
    setInvestorContext({
      ...investorContext,
      selectedInvestorId: investor?.id,
      selectedTransactionId: undefined,
    });
  };

  const handleSelectedTransaction = (transaction) => {
    setInvestorContext({
      ...investorContext,
      selectedInvestorId: transaction.investor.id,
      selectedTransactionId: transaction?.id,
    });
  };

  const setInvestorFilter = (key, value) => {
    setInvestorContext({
      ...investorContext,
      filters: {
        ...investorContext.filters,
        [key]: value,
      },
    });
  };

  const setInvestorOptions = (options) => {
    setInvestorContext({
      ...investorContext,
      options,
    });
  };

  const setInvestorSort = (sortOption) => {
    setInvestorContext({
      ...investorContext,
      sortOption,
    });
  };

  return (
    <InvestorContext.Provider
      value={{
        investorContext,
        setInvestorFilter,
        setInvestorOptions,
        setInvestorSort,
        handleCurrentView,
        handleSelectedInvestor,
        handleSelectedTransaction,
      }}
    >
      {children}
    </InvestorContext.Provider>
  );
};

export const useInvestorContext = () => {
  return useContext(InvestorContext);
};
