import React, { useEffect, useState, useRef, useMemo } from "react";
import { privateFetch } from "../../../utils/apiHelper";
import IntermediateDatatable from "../../../modules/intermediate/IntermediateDatatable";
import IntermediateDetail from "../../../modules/intermediate/IntermediateDetail";
import ManageIntermediate from "../../../modules/managers/ManageIntermediate";
import ManageIntermediateAccount from "../../../modules/managers/ManageIntermediateAccount";
import ManageDocument from "../../../modules/managers/ManageDocument";
import "../../Pages.scss";
import { useAccountContext } from "../../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { confirmDialog } from "primereact/confirmdialog";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import CommentFeed from "../../../components/Comment/CommentFeed";
import { useIntermediateContext } from "../../../contexts/IntermediateContext";
import slugify from "react-slugify";
import { useDebouncedCallback } from "use-debounce";
import ManageComment from "../../../components/Comment/ManageComment";

function IntermediatePage({ hidden }) {
  const exportRef = useRef(null);
  const {
    authorizationsContext,
    getSubappAuthorizationType,
    getAuthorizedScopeList,
  } = useAuthorizationsContext();
  const {
    intermediateContext,
    setIntermediateFilter,
    setIntermediateOptions,
    handleSelectedIntermediate,
  } = useIntermediateContext();
  const { accountContext } = useAccountContext();
  const { navigationContext, handleIntermediateView } = useNavigationContext();

  const [datatableLoading, setDatatableLoading] = useState(true);
  const [intermediateList, setIntermediateList] = useState(undefined);
  const [intermediate, setIntermediate] = useState(null);
  const [intermediateAccount, setIntermediateAccount] = useState(null);
  const [intermediateModuleOpen, setIntermediateModuleOpen] = useState(false);
  const [intermediateAccountModuleOpen, setIntermediateAccountModuleOpen] =
    useState(false);
  const [activeScopeList, setActiveScopeList] = useState([]);
  const [document, setDocument] = useState(null);
  const [mandatoryDefaultType, setMandatoryDefaultType] = useState(undefined);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commentManagerIsOpen, setCommentManagerIsOpen] = useState(false);
  const [commentList, setCommentList] = useState(undefined);

  useEffect(() => {
    setActiveScopeList(getAuthorizedScopeList());
  }, []);

  useEffect(() => {
    fetchIntermediateList();
  }, []);

  useEffect(() => {
    if (intermediate) {
      fetchCommentList(intermediate);
    }
  }, [intermediate]);

  useEffect(() => {
    refreshIntermediateList();
  }, [intermediateContext]);

  useEffect(() => {
    intermediateContext?.selectedIntermediateId && fetchIntermediateDetail();
  }, [intermediateContext?.selectedIntermediateId]);

  const refreshIntermediateList = useDebouncedCallback(() => {
    fetchIntermediateList();
  }, 500);

  const isIntermediateReadWrite = useMemo(() => {
    // If User has READ_WRITE auth on the INVESTOR Subapp for at least one scope
    return getAuthorizedScopeList().some(
      (s) => getSubappAuthorizationType(s.name, "INTERMEDIATE") === "READ_WRITE"
    );
  }, [authorizationsContext]);

  const fetchIntermediateList = async () => {
    setDatatableLoading(true);
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/?query=${slugify(
        intermediateContext.filters.query
      )}&linked_scopes=${intermediateContext.filters.scopeList.map(
        (s) => s.id
      )}`
    ).then((res) => {
      setIntermediateList(res);
      setDatatableLoading(false);
    });
  };

  const fetchIntermediateDetail = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/${intermediateContext?.selectedIntermediateId}/`
    ).then((res) => {
      if (res) {
        setIntermediate(res);
      } else {
        handleIntermediateView("DATATABLE");
        setIntermediate(undefined);
      }
    });
  };

  const createIntermediate = async (intermediateForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/intermediate/`,
      "Une erreur est survenue lors de la création de l'intermédiaire",
      "L'intermédiaire a bien été créé.",
      JSON.stringify(intermediateForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setIntermediateModuleOpen(false);
        fetchIntermediateList();
        handleSelectedIntermediate(response);
        handleIntermediateView("INTERMEDIATE");
      }
    });
  };

  const updateIntermediate = async (intermediateForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/`,
      "Une erreur est survenue lors de la modification de l'intermédiaire",
      "L'intermédiaire a bien été modifié.",
      JSON.stringify(intermediateForm)
    ).then(() => {
      fetchIntermediateDetail(intermediate);
      setIntermediateModuleOpen(false);
      setLoading(false);
    });
  };

  const deleteIntermediate = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/`,
      "Une erreur est survenue lors de la suppression de l'intermédiaire",
      "L'intermédiaire a bien été supprimé."
    ).then((res) => {
      fetchIntermediateList();
      handleIntermediateView("DATATABLE");
      setIntermediateModuleOpen(false);
      setLoading(false);
    });
  };

  const createIntermediateAccount = async (accountForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/account/`,
      null,
      "Le compte utilisateur a bien été créé.",
      JSON.stringify(accountForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setIntermediateAccountModuleOpen(false);
        fetchIntermediateList();
        fetchIntermediateDetail(intermediate);
      }
    });
  };

  const updateIntermediateAccount = async (accountForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/account/${intermediateAccount.id}/`,
      "Une erreur est survenue lors de la modification du compte utilisateur",
      "Le compte utilisateur a bien été modifié.",
      JSON.stringify(accountForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setIntermediateAccountModuleOpen(false);
        fetchIntermediateList();
        fetchIntermediateDetail(intermediate);
      }
    });
  };

  const deleteIntermediateAccount = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/account/${intermediateAccount.id}/`,
      "Une erreur est survenue lors de la suppression du compte intermédiaire",
      "Le compte intermédiaire a bien été supprimé."
    ).then((res) => {
      fetchIntermediateList();
      fetchIntermediateDetail(intermediate);
      setIntermediateAccount(undefined);
      setLoading(false);
    });
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchIntermediateDetail(intermediate);
    });
  };

  const updateDocument = async (document, documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchIntermediateDetail(intermediate);
    });
  };

  const deleteDocument = async (document) => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchIntermediateDetail(intermediate);
    });
  };

  const createComment = async (commentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/comment/`,
      "Une erreur est survenue lors de l'ajout de commentaire.",
      `Le commentaire a bien été créé.`,
      JSON.stringify(commentForm)
    ).then(() => {
      setLoading(false);
      setCommentManagerIsOpen(false);
      fetchIntermediateDetail(intermediate);
    });
  };

  const fetchCommentList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/comment/`
    ).then((res) => {
      setCommentList(res);
    });
  };

  const handleIntermediateSelection = async (targetIntermediate) => {
    if (!intermediate || targetIntermediate.id !== intermediate.id) {
      await fetchIntermediateDetail(targetIntermediate);
    }
    handleSelectedIntermediate(targetIntermediate);
    handleIntermediateView("INTERMEDIATE");
  };

  const confirmDocumentDeletion = (document) => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${document.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteDocument(document),
    });
  };

  const confirmIntermediateDeletion = (intermediate) => {
    setIntermediateModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer l'intermédiaire ${intermediate.name} et tous les comptes associés ?`,
      header: "Action irrévesrible",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteIntermediate(intermediate),
    });
  };

  const confirmIntermediateAccountDeletion = (intermediateAccount) => {
    setIntermediateAccountModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le compte intermédiaire ${intermediateAccount.alias} ?`,
      header: "Action irrévesrible",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteIntermediateAccount(intermediateAccount),
    });
  };

  const handleOpenCommentManager = () => {
    setCommentManagerIsOpen(true);
  };

  const handleCreateIntermediate = () => {
    setIntermediate(undefined);
    setIntermediateModuleOpen(true);
  };

  const handleIntermediateUpdate = () => {
    setIntermediateModuleOpen(true);
  };

  const handleCreateIntermediateAccount = () => {
    setIntermediateAccount(undefined);
    setIntermediateAccountModuleOpen(true);
  };

  const handleUpdateIntermediateAccount = (account) => {
    setIntermediateAccount(account);
    setIntermediateAccountModuleOpen(true);
  };

  const handleCreateIntermediateDocument = () => {
    setDocument(undefined);
    setDocumentModuleOpen(true);
    setMandatoryDefaultType(undefined);
  };

  const handleUpdateIntermediateDocument = (document, defaultType) => {
    setDocument(document);
    setDocumentModuleOpen(true);
    setMandatoryDefaultType(defaultType);
  };

  const downloadDocument = (document) => {
    if (document) window.open(document.file, "_blank");
  };

  const breadcrumbItems = () => {
    const items = [
      {
        label: "Intermédiaires",
        onClick: () => handleIntermediateView("DATATABLE"),
        isActive: navigationContext?.intermediateView === "DATATABLE",
      },
    ];
    // intermediate view
    intermediate &&
      items.push({
        label: `${intermediate.name}`,
        onClick: () => handleIntermediateView("INTERMEDIATE"),
        isActive: navigationContext?.intermediateView === "INTERMEDIATE",
      });
    return items;
  };

  const mainContent = () => {
    switch (navigationContext?.intermediateView) {
      case "DATATABLE":
        return (
          <div className="p-4 w-full overflow-y-hidden">
            <div className="full-page-datatable">
              <IntermediateDatatable
                loading={datatableLoading}
                exportRef={exportRef}
                data={intermediateList}
                selectItem={handleIntermediateSelection}
                handleCreateIntermediate={
                  isIntermediateReadWrite && handleCreateIntermediate
                }
              />
            </div>
          </div>
        );
      case "INTERMEDIATE":
        return (
          <div className="flex flex-row h-full">
            <div className="w-3/4 h-auto overflow-y-scroll">
              <IntermediateDetail
                intermediate={intermediate}
                handleIntermediateUpdate={
                  isIntermediateReadWrite && handleIntermediateUpdate
                }
                handleCreateAccount={
                  isIntermediateReadWrite && handleCreateIntermediateAccount
                }
                handleUpdateAccount={
                  isIntermediateReadWrite && handleUpdateIntermediateAccount
                }
                handleCreateDocument={
                  isIntermediateReadWrite && handleCreateIntermediateDocument
                }
                handleUpdateDocument={
                  isIntermediateReadWrite
                    ? handleUpdateIntermediateDocument
                    : downloadDocument
                }
              />
            </div>
            <div className="w-1/4 h-full overflow-y-scroll">
              <CommentFeed
                commentList={commentList}
                handleCreateComment={handleOpenCommentManager}
              />
            </div>
            <ManageComment
              loading={loading}
              isOpen={commentManagerIsOpen}
              handleClose={() => {
                setCommentManagerIsOpen(false);
              }}
              createComment={createComment}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Header */}
      <BreadCrumb items={breadcrumbItems()} />
      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper">
        {mainContent()}
      </div>
      {/* Managers */}
      <ManageIntermediate
        intermediate={intermediate}
        isOpen={intermediateModuleOpen}
        handleClose={() => setIntermediateModuleOpen(false)}
        handleCreation={createIntermediate}
        handleUpdate={updateIntermediate}
        handleDelete={() => confirmIntermediateDeletion(intermediate)}
        loading={loading}
      />

      <ManageIntermediateAccount
        intermediate={intermediate}
        intermediateAccount={intermediateAccount}
        isOpen={intermediateAccountModuleOpen}
        handleClose={() => setIntermediateAccountModuleOpen(false)}
        handleCreate={createIntermediateAccount}
        handleUpdate={updateIntermediateAccount}
        handleDelete={() =>
          confirmIntermediateAccountDeletion(intermediateAccount)
        }
        loading={loading}
      />

      <ManageDocument
        loading={loading}
        document={document}
        options={CONSTANTS.INTERMEDIATE_DOCUMENTS}
        mandatoryType={mandatoryDefaultType || "other"}
        isOpen={documentModuleOpen}
        handleClose={() => setDocumentModuleOpen(false)}
        uploadDocument={uploadDocument}
        updateDocument={(documentForm) =>
          updateDocument(document, documentForm)
        }
        deleteDocument={() => confirmDocumentDeletion(document)}
      />
    </div>
  );
}

export default IntermediatePage;
